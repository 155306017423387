import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Grid,
  TextareaAutosize,
  Typography,
  Tooltip,
  Box,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { DEBOUNCE_TIMEOUT, DOCUSIGN_SCOPE_REDIRECT_URL } from 'constants/config';
import { debounce } from 'lodash';

import { IReviewer } from '../../../../components/Reviewers/interfaces';
import './index.scss';

import { useLazyQuery, useMutation } from '@apollo/client';
import { CONTRACT_DOCUSIGN_CREATE, CONTRACT_DOCUSIGN_INFO } from 'graphql/legalFolders/docusign';
import {
  contractDocuSignInfo,
  contractDocuSignInfo_contract_docuSignInfo,
} from 'graphql/legalFolders/types/contractDocuSignInfo';
import { tryUpdateProcedure } from 'utils/apollo';

import { useUI } from 'contexts/UiContext';
import LoadingOverlay from 'react-loading-overlay-ts';

import { useCurrentUser } from 'hooks/currentUserHook';
import { docuSignErrorApiCodes } from 'constants/docusignErrorApiCodes';

import { DocumentDocusignSigner } from 'graphql/legalFolders/types/graphql-types';
import { DocusignSignersAnyUserSelection } from '../DocusignSignersAnyUserSelection/DocusignSignersAnyUserSelection';
import { DocusignRecipientsAnyUserSelection } from '../DocusignRecipientsAnyUserSelection/DocusignRecipientsAnyUserSelection';

export interface IDocuSignSectionProps {
  reviewers: IReviewer[];
  documentId: string;
  documentVersionId: string;
  documentFileId: string;
  hasPendingDocusign: boolean;
  refetchDocument: () => void;
}

export interface IDocuSignCCRecipient {
  userId?: string | null;
  name?: string | null;
  email?: string | null;
}

export interface IDocuSignSectionSigner extends Partial<DocumentDocusignSigner> {
  userId?: string | null;
  name?: string | null;
  email?: string | null;
}
export interface IDocuSignSectionSigners {
  signers: IDocuSignSectionSigner[];
}

export const DocuSignSection: FC<IDocuSignSectionProps> = ({
  documentId,
  documentVersionId,
  documentFileId,
  refetchDocument,
  hasPendingDocusign,
}) => {
  const { currentAccount } = useCurrentUser();

  const { addSnackbar } = useUI();
  const [expanded, setExpanded] = useState(false);
  const [isCustomMessage, setIsCustomMessage] = useState(false);
  const [emailBody, setEmailBody] = useState<string>('');
  const [docusignInfo, setDocusignInfo] = useState<contractDocuSignInfo_contract_docuSignInfo>();
  const [docusignInProgress, setDocusignInProgress] = useState(false);

  const [docusignSignMutation] = useMutation(CONTRACT_DOCUSIGN_CREATE);

  const [
    queryDocusignInfo,
    {
      data: docuSignInfoData,
      loading: docuSignInfoLoading,
      refetch: docuSignInfoRefetch,
      called: docuSignInfoCalled,
      error: docuSignInfoError,
    },
  ] = useLazyQuery<contractDocuSignInfo>(CONTRACT_DOCUSIGN_INFO, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (isCustomMessage) {
      setEmailBody(() => '');
    }
  }, [isCustomMessage]);

  const loadDocusignInfo = useCallback(() => {
    const variables = {};
    if (docuSignInfoCalled) {
      docuSignInfoRefetch!(variables);
    } else {
      queryDocusignInfo({ variables });
    }
  }, [docuSignInfoCalled, docuSignInfoRefetch, queryDocusignInfo]);

  const [selectedReviewers, setSelectedReviewers] = useState<IDocuSignSectionSigners[]>([
    { signers: [{}] },
  ]);

  const [ccRecipients, setCCRecipients] = useState<IDocuSignCCRecipient[]>([]);

  const handleInitiateDocusign = useCallback(() => {
    setExpanded(true);
  }, []);

  const handleCancelDocusign = useCallback(() => {
    setExpanded(false);
  }, []);

  const onChangeEmailBody = useCallback((event: any): void => {
    setEmailBody(event?.target?.value);
  }, []);

  const handleSignerSelection = useCallback(
    (no: number) =>
      (value: any): void => {
        setSelectedReviewers((old) => [
          ...old.slice(0, no),
          { signers: value },
          ...old.slice(no + 1),
        ]);
      },
    []
  );

  const handleRemoveSection = useCallback(
    (no: number) => (): void => {
      setSelectedReviewers((old) => [...old.slice(0, no), ...old.slice(no + 1)]);
    },
    []
  );

  const handleAddNextSigners = useCallback(() => {
    setSelectedReviewers((old) => [...old, { signers: [{}] }]);
  }, []);

  const getSigners = useCallback(() => {
    let routingOrder = 0;
    const signers: DocumentDocusignSigner[] = [];
    for (const signerGroup of selectedReviewers) {
      routingOrder++;
      for (const signer of signerGroup.signers) {
        signers.push({ ...signer, routingOrder });
      }
    }
    return signers;
  }, [selectedReviewers]);

  const getCCUsers = useCallback(() => {
    return ccRecipients.filter(({ userId, name, email }) => userId || (name && email));
  }, [ccRecipients]);

  const handleSubmitDocusign = useCallback(async () => {
    setDocusignInProgress(true);
    const { result, isError, errors } = await tryUpdateProcedure({
      mutation: () =>
        docusignSignMutation({
          variables: {
            documentId,
            documentVersionId,
            documentFileId,
            signers: getSigners(),
            ccUsers: getCCUsers(),
            emailBody,
          },
          errorPolicy: 'all',
        }),
      parseResult: (data: any) => {
        return data;
      },
    });
    if (result?.contract_documentDocusignCreate?.envelopeId) {
      addSnackbar!({
        text: 'Success',
        severity: 'success',
      });
    } else {
      if (isError) {
        // parse docusign response
        // ex. text: {\"errorCode\":\"UNABLE_TO_CONVERT_DOCUMENT\",\"message\":\"System was unable to convert this document to a PDF. Unable to convert Document(test.xxx) to a PDF. Error: UserId:d10d075c-6c4d-406e-917b-20e1ffef8a19 IPAddress:31.45.213.144 Source:ApiRESTv2_1:FileType xxx is ineligible for conversion.\"}"
        const messages = errors
          ?.map((error: any) => {
            const message = error?.extensions?.exception?.response?.text ?? error?.message;
            if (!message) {
              return '';
            }
            let messageJson: any;
            try {
              messageJson = JSON.parse(message);
            } catch {}
            if (!!messageJson && JSON.stringify(messageJson) !== '{}') {
              const errorCode = docuSignErrorApiCodes.find(
                (item) => item.code === messageJson.errorCode
              );
              if (errorCode) {
                return error?.message + ' ! ' + errorCode.text;
              }
            }
            return error?.message || undefined;
          })
          .filter((message: string | undefined) => !!message);
        if (messages?.length) {
          addSnackbar!({
            text: messages.join('.'),
            severity: 'error',
          });
        } else {
          addSnackbar!({
            text: 'Error',
            severity: 'error',
          });
        }
      }
    }

    setSelectedReviewers([{ signers: [{}] }]);
    setExpanded(false);
    setDocusignInProgress(false);
    refetchDocument();
  }, [
    emailBody,
    documentFileId,
    documentId,
    documentVersionId,
    docusignSignMutation,
    addSnackbar,
    getSigners,
    getCCUsers,
    refetchDocument,
  ]);

  useEffect(() => {
    loadDocusignInfo();
  }, [loadDocusignInfo]);

  useEffect(() => {
    if (docuSignInfoData && !docuSignInfoLoading && !docuSignInfoError) {
      setDocusignInfo(docuSignInfoData?.contract_docuSignInfo);
    }
  }, [docuSignInfoData, docuSignInfoError, docuSignInfoLoading]);

  const scopeGrantRequestCallback = useMemo(() => {
    if (!docusignInfo?.requestScopeConsentUrl) {
      return undefined;
    }
    return docusignInfo?.requestScopeConsentUrl.replace(
      '{redirectUrl}',
      encodeURIComponent(DOCUSIGN_SCOPE_REDIRECT_URL)
    );
  }, [docusignInfo]);

  const isCurrentAccountSender = useMemo(() => {
    const accountEmail: string =
      (currentAccount?.idToken?.email as string | undefined) ||
      currentAccount?.idToken?.preferred_username ||
      '';
    if (accountEmail === docusignInfo?.sender.email) {
      return true;
    }
    return false;
  }, [currentAccount?.idToken, docusignInfo?.sender]);

  return (
    <Grid container alignContent="center" alignItems="center" spacing={3}>
      <Grid item xs={12}>
        <Typography variant="body2" className="label-title">
          DOCUSIGN
        </Typography>
      </Grid>
      {expanded ? (
        <Grid item xs={12}>
          <LoadingOverlay active={docusignInProgress} spinner text="In progress...">
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Typography variant="body2" className="label-title">
                  Sender:
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                {docuSignInfoData ? (
                  <div>
                    <Tooltip title={docusignInfo?.sender.email || ''}>
                      <div style={{ width: 'fit-content' }}>{docusignInfo?.sender.name}</div>
                    </Tooltip>
                    {!docusignInfo?.senderGranted ? (
                      isCurrentAccountSender ? (
                        <a href={scopeGrantRequestCallback} style={{ color: 'red' }}>
                          Your consent is required to enable API connection to DocuSign
                        </a>
                      ) : (
                        <a href={scopeGrantRequestCallback} style={{ color: 'red' }}>
                          Sender consent is required to enable API connection to DocuSign
                        </a>
                      )
                    ) : undefined}
                  </div>
                ) : undefined}
                {docuSignInfoError ? (
                  <div style={{ color: 'red' }}>{docuSignInfoError.message}</div>
                ) : undefined}
              </Grid>
            </Grid>
            {selectedReviewers.map((_, no) => {
              return (
                <Box mt={2}>
                  <DocusignSignersAnyUserSelection
                    no={no}
                    disabled={!docusignInfo?.senderGranted}
                    handleSignerSelection={handleSignerSelection(no)}
                    onRemoveSection={
                      selectedReviewers.length > 1 ? handleRemoveSection(no) : undefined
                    }
                    value={selectedReviewers[no]}
                  />
                </Box>
              );
            })}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  className="docusign-section-btn btn-responsive"
                  variant="contained"
                  color="primary"
                  onClick={debounce(handleAddNextSigners, DEBOUNCE_TIMEOUT)}
                  disabled={!docusignInfo?.senderGranted}
                >
                  Add Next
                </Button>
              </Grid>
            </Grid>
            <Box mt={2} />
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={3}
                style={{ display: 'flex', alignItems: 'start', paddingTop: '19px' }}
              >
                <Typography variant="body2" className="label-title">
                  Receives a Copy:
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <DocusignRecipientsAnyUserSelection
                  handleRecipientSelection={setCCRecipients}
                  disabled={!docusignInfo?.senderGranted}
                  label="recipient"
                  recipientTypeLabel="recipient"
                  value={ccRecipients}
                />
              </Grid>
            </Grid>
            <Box mt={2} />
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={3}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography variant="body2" className="label-title">
                  Message:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={!!isCustomMessage}
                      onChange={() => setIsCustomMessage(!isCustomMessage)}
                    ></Switch>
                  }
                  label={isCustomMessage ? 'Custom Email Message' : 'DocuSign Default Message'}
                  labelPlacement="end"
                />
              </Grid>
              {isCustomMessage ? (
                <>
                  <Grid item xs={12} md={3}>
                    <Typography variant="body2" className="label-title">
                      Email Message:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <TextareaAutosize
                      color="grey"
                      style={{
                        width: '85%',
                        maxWidth: '85%',
                        minWidth: '85%',
                        minHeight: '4rem',
                        padding: 10,
                        borderRadius: 4,
                        overflow: 'auto',
                        border: '1px #ccc solid',
                      }}
                      aria-label="minimum height"
                      minRows={1}
                      placeholder="Write a message to all recipients..."
                      className="MuiInputBase-input"
                      onChange={onChangeEmailBody}
                      disabled={!docusignInfo?.senderGranted}
                    />
                    <br />
                  </Grid>
                </>
              ) : undefined}
            </Grid>
          </LoadingOverlay>
        </Grid>
      ) : undefined}
      <Grid item xs={12}>
        {expanded ? (
          <>
            <Button
              className="docusign-section-btn btn-responsive"
              variant="contained"
              color="primary"
              onClick={debounce(handleSubmitDocusign, DEBOUNCE_TIMEOUT)}
              disabled={
                !docusignInfo?.senderGranted ||
                !selectedReviewers?.length ||
                !!selectedReviewers.find(
                  (signerGroup) =>
                    !signerGroup.signers?.length ||
                    !!signerGroup.signers.find(({ email, name }) => !email || !name)
                ) ||
                !!ccRecipients.find(
                  ({ email, name, userId }) => !userId && (name || email) && (!name || !email)
                )
              }
            >
              Submit Docusign Request
            </Button>
            <Button
              className="docusign-section-btn btn-responsive"
              variant="contained"
              color="secondary"
              onClick={debounce(handleCancelDocusign, DEBOUNCE_TIMEOUT)}
            >
              Cancel
            </Button>
          </>
        ) : (
          <div style={{ display: 'flex' }}>
            <div>
              <Button
                className="docusign-section-btn btn-responsive"
                variant="contained"
                color="primary"
                onClick={debounce(handleInitiateDocusign, DEBOUNCE_TIMEOUT)}
                disabled={hasPendingDocusign}
              >
                Initiate Docusign
              </Button>
            </div>
            {hasPendingDocusign ? (
              <div style={{ paddingTop: '6px', lineHeight: '1.75', color: 'red' }}>
                There is a Pending DocuSign Request
              </div>
            ) : undefined}
          </div>
        )}
      </Grid>
    </Grid>
  );
};
