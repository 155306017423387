import { GET_PROJECT_FOLDER } from 'graphql/legalFolders/legalFolders';
import React, { FC } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useParams } from 'react-router';
import { projectFolder } from 'graphql/legalFolders/types/projectFolder';
import { DocumentView } from 'template/LegalFolderDocument/components/DocumentView/DocumentView';
import { Provider as LegalFolderDocumentContextProvider } from 'template/LegalFolderDocument/LegalFolderDocumentContext';
import { useQuery } from '@apollo/client';

interface ILegalFolderUrlParams {
  id: string;
}

export const NewLegalFolderDocumentTemplate: FC = () => {
  const { id } = useParams<ILegalFolderUrlParams>();

  const { data, loading } = useQuery<projectFolder>(GET_PROJECT_FOLDER, {
    variables: {
      id,
    },
  });

  return (
    <LoadingOverlay spinner active={loading} text="Loading your content...">
      <LegalFolderDocumentContextProvider loadedLegalFolder={data?.contract_container}>
        <DocumentView></DocumentView>
      </LegalFolderDocumentContextProvider>
    </LoadingOverlay>
  );
};
