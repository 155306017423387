import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Tooltip, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { uniqueId, startCase, toLower, join } from 'lodash';

import { EventCommentLogProps } from './interfaces';

import { IEvent } from 'template/LegalFolderDocument/LegalFolderDocumentContext';
import { DocumentEventType } from 'constants/enums';
import { UserInitials } from 'components';
import { documentStatusColor } from 'utils/document';
import { formatTime } from 'utils/time';
import s from './style.module.scss';
import { useUsersList } from './usersListHook';
import { capitalizeFirstLetter } from 'utils/formats';
import { EventCommentReplySubmit } from '../EventCommentReplySubmit/EventCommentReplySubmit';

const changeReportStatusName = (status: string): string => {
  return status
    .replace(/Archived/gi, 'Retired')
    .replace(/Archive/gi, 'Retire')
    .replace(/Report/gi, 'Document')
    .replace(/Party/gi, 'Other Party')
    .replace(/Document Docusign Envelope/gi, 'DocuSign');
};

export const getVersion = (data: any): string =>
  data?.version ? ` v1.${data?.version?.version}` : 'v0.0';

export const EventCommentLog: FC<EventCommentLogProps> = ({
  events = [],
  documentArchived,
  documentId,
  refetchDocument,
}: EventCommentLogProps) => {
  const { usersMap, loadUsers } = useUsersList();
  const [usersMapRequired, setUsersMapRequired] = useState(false);

  const showCommentRef = useRef<any>();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (usersMapRequired) {
      loadUsers();
    }
  }, [usersMapRequired, loadUsers]);

  const documentMovedEventInfo = useCallback((val: IEvent): JSX.Element => {
    let payload;
    if (val?.payload) {
      try {
        payload = JSON.parse(val?.payload);
      } catch {
        console.log('Payload is not in JSON format');
      }
    }
    return (
      <>
        <div>From Project Folder: #{payload?.oldContainerId}</div>
        <div>To Project Folder: #{payload?.newContainerId}</div>
      </>
    );
  }, []);

  const docusignEnvelopeEventInfo = useCallback((val: IEvent): JSX.Element => {
    let payload;
    if (val?.payload) {
      try {
        payload = JSON.parse(val?.payload);
      } catch {
        console.log('Payload is not in JSON format');
      }
    }

    return (
      <>
        {payload.event ? (
          <>
            <br />
            Event: {capitalizeFirstLetter(payload.event.replace('-', ' '))}
          </>
        ) : undefined}
        {payload.data?.envelopeSummary?.status ? (
          <>
            <br />
            Document status:{' '}
            {capitalizeFirstLetter(payload.data.envelopeSummary.status.replace('-', ' '))}
          </>
        ) : undefined}
        {payload.data?.envelopeId ? (
          <>
            <br />
            id: {payload.data.envelopeId}
          </>
        ) : undefined}
      </>
    );
  }, []);

  const docusignEventInfo = useCallback((val: IEvent): JSX.Element => {
    let payload;
    if (val?.payload) {
      try {
        payload = JSON.parse(val?.payload);
      } catch {
        console.log('Payload is not in JSON format');
      }
    }
    return (
      <>
        {payload.status ? (
          <>
            <br />
            Status: {capitalizeFirstLetter(payload.status)}
          </>
        ) : undefined}
        {/* {payload.oldStatus ? (
          <>
            <br />
            Previous Status: {capitalizeFirstLetter(payload.oldStatus)}
          </>
        ) : undefined} */}
        {/* {payload.sender ? (
          <>
            <br />
            Sender: {payload.sender.name} {'<'}
            {payload.sender.email}
            {'>'}
          </>
        ) : undefined} */}
        {payload.comment ? (
          <>
            <br />
            <div
              style={{
                display: 'inline-block',
                border: '1px solid #ccc',
                padding: '11px 10px',
                borderRadius: '3px',
                width: '100%',
              }}
            >
              {payload.comment}
            </div>
          </>
        ) : undefined}
        {payload.envelopeId ? (
          <>
            <br />
            id: {payload.envelopeId}
          </>
        ) : undefined}
        {payload.signers ? (
          <>
            <br />
            {payload.signers
              .slice()
              .sort(
                (a: any, b: any) => +parseInt(a.routingOrder || 0) - +parseInt(b.routingOrder || 0)
              )
              .map((signer: any) => {
                return (
                  <>
                    <Tooltip
                      title={
                        <>
                          {signer.routingOrder ? (
                            <>
                              Sign Order: #{signer.routingOrder}
                              <br />
                            </>
                          ) : undefined}
                          {`${signer.name} (${signer.email})`}
                        </>
                      }
                    >
                      <div style={{ display: 'inline-block', transform: 'scale(0.7)' }}>
                        <UserInitials {...signer} />
                      </div>
                    </Tooltip>
                  </>
                );
              })}
          </>
        ) : undefined}
      </>
    );
  }, []);

  const commentByEvent = useCallback((val: IEvent): JSX.Element => {
    let text;
    if (val?.payload) {
      try {
        text = JSON.parse(val?.payload).comment;
      } catch {
        console.log('Payload is not in JSON format');
      }
    }

    return (
      <>
        {text && '.'}
        {text && (
          <>
            <br />
            {text}
          </>
        )}
      </>
    );
  }, []);

  const eventType = useCallback(
    (val: IEvent): JSX.Element => (
      <span style={{ color: documentStatusColor(val) }}>
        {changeReportStatusName(startCase(toLower(val?.eventType)))}
      </span>
    ),
    []
  );

  // const content = (val: IEvent): JSX.Element => {
  //   return <div>{val.payload}</div>;
  // };

  const documentCreated = useCallback(
    (val: IEvent): JSX.Element => (
      <div className={s.eventLogItem}>
        {eventType(val)}
        {commentByEvent(val)}
      </div>
    ),
    [commentByEvent, eventType]
  );

  const documentUpdated = (): JSX.Element => <div>Document Information Updated</div>;

  const documentReviewersUpdated = (): JSX.Element => <div>Document Reviewers Updated</div>;

  const documentReviewRequested = useCallback(
    (val: IEvent): JSX.Element => (
      <div>
        {eventType(val)} for {val?.subject?.user?.name || 'Unknown'}
      </div>
    ),
    [eventType]
  );

  const documentVersionDeleted = useCallback(
    (val: IEvent): JSX.Element => {
      let jsonPayload;
      if (val?.payload) {
        try {
          jsonPayload = JSON.parse(val?.payload);
        } catch {}
      }
      const version = jsonPayload?.version;

      if (jsonPayload?.reviewReversalReviewerUserIds?.length && jsonPayload) {
        if (!usersMapRequired) {
          setUsersMapRequired(true);
        }
      }

      return (
        <div className={s.eventLogItem}>
          {eventType(val)}
          {version ? (
            <>
              <br />
              Version: {getVersion({ version: { version } })}
            </>
          ) : undefined}
          {commentByEvent(val)}
          {val.version?.file?.originalFilename ? (
            <>
              <br />
              Filename: {val.version?.file?.originalFilename}
            </>
          ) : undefined}
          {jsonPayload?.reviewReversalReviewerUserIds?.length &&
          jsonPayload?.reviewReversalToStatus &&
          !!usersMap ? (
            <>
              <br />
              Review status reverted to "
              {capitalizeFirstLetter(
                (jsonPayload.reviewReversalToStatus as string).toLowerCase().replace('_', ' ')
              )}
              " for{' '}
            </>
          ) : undefined}
          {jsonPayload?.reviewReversalReviewerUserIds?.length && !!usersMap
            ? join(
                jsonPayload?.reviewReversalReviewerUserIds.map((id: string) => {
                  return usersMap[id] ? usersMap[id].name || usersMap[id].email : 'User ' + id;
                }),
                ', '
              )
            : undefined}
        </div>
      );
    },
    [commentByEvent, eventType, usersMap, usersMapRequired]
  );

  const documentVersionCreated = useCallback(
    (val: IEvent): JSX.Element => {
      return (
        <div className={s.eventLogItem}>
          {eventType(val)}
          {val.version?.file?.originalFilename ? (
            <>
              <br />
              Filename: {val.version?.file?.originalFilename}
            </>
          ) : undefined}
          {commentByEvent(val)}
        </div>
      );
    },
    [commentByEvent, eventType]
  );

  const documentUserEvent = useCallback(
    (val: IEvent): JSX.Element => {
      return (
        <div className={s.eventLogItem}>
          {eventType(val)}
          {commentByEvent(val)}
        </div>
      );
    },
    [commentByEvent, eventType]
  );

  const documentClientApprovalStatusChanged = useCallback(
    (val: IEvent): JSX.Element => {
      let jsonPayload;
      if (val?.payload) {
        try {
          jsonPayload = JSON.parse(val?.payload);
        } catch {}
      }
      const status =
        jsonPayload && jsonPayload.status
          ? 'status : ' + startCase(toLower(jsonPayload.status))
          : undefined;
      const isRequired =
        jsonPayload && jsonPayload.isRequired !== undefined
          ? 'Required set to ' + jsonPayload.isRequired.toString()
          : undefined;
      return (
        <div>
          {eventType(val)} <br />
          {status ? (
            <>
              {status} <br />{' '}
            </>
          ) : undefined}
          {isRequired ? (
            <>
              {isRequired} <br />{' '}
            </>
          ) : undefined}
        </div>
      );
    },
    [eventType]
  );

  const documentOwnerChanged = useCallback(
    (val: IEvent): JSX.Element => {
      // val.payload is like {"ownerUserId":"294"}
      return <div>{eventType(val)}</div>;
    },
    [eventType]
  );

  const documentMonetaryTtransactionBreakdownChanged = useCallback(
    (val: IEvent): JSX.Element => {
      // val.payload is like {"monetaryTransactionBreakdownsToAdd":[{"value":1111111,"startTimestamp":"2020-01-31T23:00:00.000Z"}]}
      return <div>{eventType(val)}</div>;
    },
    [eventType]
  );

  const documentReviewRecalled = useCallback(
    (val: IEvent): JSX.Element => (
      <div>
        {eventType(val)} for {val?.author?.user?.name}
      </div>
    ),
    [eventType]
  );

  const documentReviewResent = useCallback(
    (val: IEvent): JSX.Element => {
      return (
        <div>
          {eventType(val)} for {val?.subject?.user?.name}
        </div>
      );
    },
    [eventType]
  );

  const documentCommented: (val: IEvent) => JSX.Element = useCallback(
    (val: IEvent): JSX.Element => {
      let text;
      let quoteEventId;
      if (val.payload) {
        try {
          text = JSON.parse(val.payload).comment;
          quoteEventId = JSON.parse(val.payload).refEventId;
        } catch {}
      }
      return showCommentRef.current && showCommentRef.current(val, text, quoteEventId);
    },
    []
  );

  const content = useCallback(
    (val: IEvent): JSX.Element => {
      switch (val?.eventType) {
        case DocumentEventType.DOCUMENT_CREATED:
          return documentCreated(val);

        case DocumentEventType.DOCUMENT_VERSION_DELETED:
          return documentVersionDeleted(val);

        case DocumentEventType.DOCUMENT_VERSION_CREATED:
          return documentVersionCreated(val);

        case DocumentEventType.DOCUMENT_COMMENTED:
        case DocumentEventType.DOCUMENT_COMMENT_REPLIED:
          return documentCommented(val);

        case DocumentEventType.DOCUMENT_OBSERVER_NOTIFICATION_SENT:
        case DocumentEventType.DOCUMENT_REVIEW_REQUESTED:
          return documentReviewRequested(val);

        case DocumentEventType.DOCUMENT_REVIEW_RESENT:
          return documentReviewResent(val);

        case DocumentEventType.DOCUMENT_PARTY_REVIEW_STATUS_CHANGED:
        case DocumentEventType.DOCUMENT_CLIENT_APPROVAL_STATUS_CHANGED:
          return documentClientApprovalStatusChanged(val);

        case DocumentEventType.DOCUMENT_UPDATED:
          return documentUpdated();

        case DocumentEventType.DOCUMENT_REVIEWERS_UPDATED:
          return documentReviewersUpdated();

        case DocumentEventType.DOCUMENT_REVIEW_RECALLED:
          return documentReviewRecalled(val);

        case DocumentEventType.DOCUMENT_REVIEW_APPROVED:
        case DocumentEventType.DOCUMENT_ALL_REVIEWS_COMPLETED:
        case DocumentEventType.DOCUMENT_REVIEW_DISAPPROVED:
        case DocumentEventType.DOCUMENT_REVIEW_POSTPONED:
        case DocumentEventType.DOCUMENT_ARCHIVED:
        case DocumentEventType.DOCUMENT_ARCHIVE_INITIATED:
        case DocumentEventType.DOCUMENT_ARCHIVE_DOCUMENT_STORED:
        case DocumentEventType.DOCUMENT_CANCELED:
          return documentUserEvent(val);

        case DocumentEventType.DOCUMENT_FILE_DELETED:
          return <div>Support Document Deleted</div>;
        case DocumentEventType.DOCUMENT_FILE_UPLOADED:
          return <div>Support Document Uploaded</div>;

        case DocumentEventType.DOCUMENT_OWNER_CHANGED:
          return documentOwnerChanged(val);

        case DocumentEventType.DOCUMENT_MONETARY_TRANSACTION_BREAKDOWN_CHANGE:
          return documentMonetaryTtransactionBreakdownChanged(val);

        case DocumentEventType.DOCUMENT_DOCUSIGN_ENVELOPE_STATUS_CHANGED:
        case DocumentEventType.DOCUMENT_DOCUSIGN_CREATED:
        case DocumentEventType.DOCUMENT_DOCUSIGN:
          return (
            <div>
              {eventType(val)}
              {docusignEventInfo(val)}
            </div>
          );

        case DocumentEventType.DOCUMENT_DOCUSIGN_ENVELOPE_DELETE:
          return (
            <div>
              Document Docusign Deleted
              {docusignEnvelopeEventInfo(val)}
            </div>
          );

        case DocumentEventType.DOCUMENT_MOVED:
          return (
            <div>
              {eventType(val)}
              {documentMovedEventInfo(val)}
            </div>
          );

        default:
          return <div>{eventType(val)}</div>;
      }
    },
    [
      docusignEventInfo,
      docusignEnvelopeEventInfo,
      eventType,
      documentClientApprovalStatusChanged,
      documentCommented,
      documentCreated,
      documentMonetaryTtransactionBreakdownChanged,
      documentOwnerChanged,
      documentReviewRecalled,
      documentReviewRequested,
      documentReviewResent,
      documentUserEvent,
      documentVersionCreated,
      documentVersionDeleted,
      documentMovedEventInfo,
    ]
  );

  const showEvent = useCallback(
    (data: any, allowComment: boolean) => {
      let jsonPayload;
      if (data?.payload) {
        try {
          jsonPayload = JSON.parse(data?.payload);
        } catch {}
      }
      if (jsonPayload?.reminder && data?.eventType === DocumentEventType.DOCUMENT_REVIEW_RESENT) {
        return (
          <Grid xs={12} key={uniqueId()}>
            <div style={{ display: 'flex', gap: '2em', width: '85%' }}>
              <div>
                <UserInitials {...data?.author?.user} />
              </div>
              <div style={{ flexGrow: 2 }}>
                <div className="event-comment-log-header">
                  <span className="event-comment-log-header-title">
                    <strong>
                      REMINDER automatically generated &nbsp;-&nbsp;
                      {getVersion(data)}
                    </strong>
                  </span>
                  <span className="event-comment-log-header-date">
                    {isSmall ? <br /> : undefined}
                    {formatTime(data?.createdAt)}
                  </span>
                </div>

                <div>{content(data)}</div>

                {allowComment && (
                  <div style={{ padding: '15px 0' }}>
                    <EventCommentReplySubmit
                      documentId={documentId}
                      refetchDocument={refetchDocument}
                      commentOnEventId={data.id}
                    ></EventCommentReplySubmit>
                  </div>
                )}
              </div>
            </div>
          </Grid>
        );
      } else {
        return (
          <Grid xs={12} key={uniqueId()}>
            <div style={{ display: 'flex', gap: '2em', width: '85%' }}>
              <div>
                <UserInitials {...data?.author?.user} />
              </div>
              <div style={{ flexGrow: 2, display: 'flex', gap: '0.5em', flexDirection: 'column' }}>
                <div className="event-comment-log-header">
                  <span className="event-comment-log-header-title">
                    <strong>
                      {user(data)}
                      &nbsp;-&nbsp;
                      {getVersion(data)}
                    </strong>
                  </span>
                  <span className="event-comment-log-header-date">
                    {isSmall ? <br /> : undefined}
                    {formatTime(data?.createdAt)}
                  </span>
                </div>

                <div>{content(data)}</div>

                {allowComment && (
                  <div style={{ padding: '15px 0' }}>
                    <EventCommentReplySubmit
                      documentId={documentId}
                      refetchDocument={refetchDocument}
                      commentOnEventId={data.id}
                    ></EventCommentReplySubmit>
                  </div>
                )}
              </div>
            </div>
          </Grid>
        );
      }
    },
    [content, isSmall, documentId, refetchDocument]
  );

  const showComment = useCallback(
    (val: IEvent, text: string, quoteEventId?: string) => {
      return (
        <div>
          <div
            className={s.eventLogItem}
            style={{
              border: '1px solid #ccc',
              padding: '11px 10px',
              borderRadius: '3px',
              width: '100%',
            }}
          >
            {quoteEventId ? (
              <>
                Quote:
                <div
                  className={s.eventLogItem}
                  style={{
                    border: '1px solid #ccc',
                    padding: '11px 10px',
                    borderRadius: '3px',
                    width: '100%',
                    backgroundColor: '#EEEEEE',
                    margin: '0 0 1em 0',
                  }}
                >
                  {showEvent(
                    events.find((event) => event.id === quoteEventId),
                    false
                  )}
                </div>
              </>
            ) : undefined}
            {text}
          </div>
        </div>
      );
    },
    [events, showEvent]
  );

  useEffect(() => {
    showCommentRef.current = showComment;
  }, [showComment]);

  const filterValues = (): IEvent[] => {
    const result = documentArchived
      ? events.filter(
          (value) =>
            value.eventType === DocumentEventType.DOCUMENT_CREATED ||
            value.eventType === DocumentEventType.DOCUMENT_REVIEW_APPROVED ||
            value.eventType === DocumentEventType.DOCUMENT_REVIEW_DISAPPROVED ||
            value.eventType === DocumentEventType.DOCUMENT_REVIEW_REQUESTED ||
            value.eventType === DocumentEventType.DOCUMENT_VERSION_CREATED ||
            value.eventType === DocumentEventType.DOCUMENT_ARCHIVED ||
            value.eventType === DocumentEventType.DOCUMENT_ARCHIVE_INITIATED ||
            value.eventType === DocumentEventType.DOCUMENT_ALL_REVIEWS_COMPLETED ||
            value.eventType === DocumentEventType.DOCUMENT_ARCHIVE_DOCUMENT_STORED
        )
      : events;

    return result;
  };

  const user = (data: any): string =>
    data?.author
      ? `${data?.author?.persona ? data?.author?.persona : ''} ${data?.author?.user?.name}`
      : 'Undefined Author';

  return (
    <Grid container padding={0} spacing={3}>
      {filterValues()
        .slice()
        .sort((a: any, b: any) => +new Date(b?.createdAt) - +new Date(a?.createdAt))
        .map((event) =>
          showEvent(
            event,
            [
              DocumentEventType.DOCUMENT_COMMENTED,
              DocumentEventType.DOCUMENT_COMMENT_REPLIED,
            ].includes(event.eventType as DocumentEventType)
          )
        )}
    </Grid>
  );
};
