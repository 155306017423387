const BASE = '/';
const LOGIN = '/login';

const REPORTS = '/reports';
const REPORT_DOCUMENTS_NOT_SIGNED_BY_ALL_PARTIES = '/reports/documents-for-parties';
const REPORT_PROJECT_FOLDERS_WO_PROJECTS = '/reports/project-folders-missing-project';

const PROJECT_FOLDERS = '/v1/project-folders';

const PROJECT_FOLDER = '/v1/new-project-folder';
const PROJECT_FOLDER_PARTY = '/v1/new-project-folder/new-party';

const PROJECT_FOLDER_ID = '/v1/project-folder/:id';
const PROJECT_FOLDER_ID_PARTY = '/v1/project-folder/:id/new-party';
const PROJECT_FOLDER_ID_DOCUMENTS = '/v1/project-folder/:id/documents';
const PROJECT_FOLDER_ID_DOCUMENT = '/v1/project-folder/:id/document'; // new document
const PROJECT_FOLDER_DOCUMENT_ID = '/v1/project-folder-document/:documentId'; // edit document
const PROJECT_FOLDER_DOCUMENT_ID_FILE_MANAGEMENT =
  '/v1/project-folder-document/:documentId/file-management';

const PARTIES = '/parties-list';
const CLIENT_MANAGEMENT = PARTIES;

const NEW_PARTY = '/new-party';
const NEW_CLIENT = NEW_PARTY;

const CLIENT_PAGES = '/party/:id/:tab';
const CLIENT_DETAILS = '/party/:id/details';
const CLIENT_EVENTS = '/party/:id/events-log';

const DOCUMENTS = '/dashboard';

const DASHBOARD = '/dashboard';

const clientPathConst = {
  BASE,
  LOGIN,
  PROJECT_FOLDERS,
  PROJECT_FOLDER,
  PROJECT_FOLDER_PARTY,
  PROJECT_FOLDER_ID,
  PROJECT_FOLDER_ID_PARTY,
  PROJECT_FOLDER_ID_DOCUMENTS,
  PROJECT_FOLDER_ID_DOCUMENT,
  PROJECT_FOLDER_DOCUMENT_ID,
  PROJECT_FOLDER_DOCUMENT_ID_FILE_MANAGEMENT,
  CLIENT_MANAGEMENT,
  PARTIES,
  DOCUMENTS,
  NEW_PARTY,
  NEW_CLIENT,
  CLIENT_PAGES,
  CLIENT_DETAILS,
  CLIENT_EVENTS,
  DASHBOARD,

  REPORTS,
  REPORT_DOCUMENTS_NOT_SIGNED_BY_ALL_PARTIES,
  REPORT_PROJECT_FOLDERS_WO_PROJECTS,
};

export default clientPathConst;
