import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { flushSync } from 'react-dom';
import { EventCommentGanttLogItem } from '../../../../EventCommentGanttLogItem/EventCommentGanttLogItem';
import { uuid } from 'uuidv4';
import { documentStatusColor } from 'utils/document';
import { capitalizeFirstLetter, changeReportStatusName } from 'utils/formats';
import { join, startCase, toLower } from 'lodash';
import { DocumentEventType } from 'constants/enums';
import s from 'template/LegalFolderDocument/components/FileManagement/components/EventCommentLogs/style.module.scss';
import { useUsersList } from '../../../../EventCommentLogs/usersListHook';
import { Tooltip } from '@mui/material';
import { getVersion } from '../../../../EventCommentLogs';
import { UserInitials } from 'components';
import { formatTime } from 'utils/time';

export interface IEvent {
  [id: string]: any;
}

export const useParseEvents = ({ events = [] }: any) => {
  const { usersMap, loadUsers } = useUsersList();
  const [usersMapRequired, setUsersMapRequired] = useState(false);
  const showCommentRef = useRef<any>();

  useEffect(() => {
    if (usersMapRequired) {
      loadUsers();
    }
  }, [usersMapRequired, loadUsers]);

  const docusignEventInfo = useCallback((val: IEvent): JSX.Element => {
    let payload;
    if (val?.payload) {
      try {
        payload = JSON.parse(val?.payload);
      } catch {
        console.log('Payload is not in JSON format');
      }
    }
    return (
      <>
        {payload.status ? (
          <>
            <br />
            Status: {capitalizeFirstLetter(payload.status)}
          </>
        ) : undefined}
        {/* {payload.oldStatus ? (
          <>
            <br />
            Previous Status: {capitalizeFirstLetter(payload.oldStatus)}
          </>
        ) : undefined} */}
        {/* {payload.sender ? (
          <>
            <br />
            Sender: {payload.sender.name} {'<'}
            {payload.sender.email}
            {'>'}
          </>
        ) : undefined} */}
        {payload.comment ? (
          <>
            <br />
            <div
              style={{
                display: 'inline-block',
                border: '1px solid #ccc',
                padding: '11px 10px',
                borderRadius: '3px',
                width: '100%',
              }}
            >
              {payload.comment}
            </div>
          </>
        ) : undefined}
        {payload.envelopeId ? (
          <>
            <br />
            id: {payload.envelopeId}
          </>
        ) : undefined}
        {payload.signers ? (
          <>
            <br />
            {payload.signers
              .slice()
              .sort(
                (a: any, b: any) => +parseInt(a.routingOrder || 0) - +parseInt(b.routingOrder || 0)
              )
              .map((signer: any) => {
                return (
                  <>
                    <Tooltip
                      title={
                        <>
                          {signer.routingOrder ? (
                            <>
                              Sign Order: #{signer.routingOrder}
                              <br />
                            </>
                          ) : undefined}
                          {`${signer.name} (${signer.email})`}
                        </>
                      }
                    >
                      <div style={{ display: 'inline-block', transform: 'scale(0.7)' }}>
                        <UserInitials {...signer} />
                      </div>
                    </Tooltip>
                  </>
                );
              })}
          </>
        ) : undefined}
      </>
    );
  }, []);

  const commentByEvent = useCallback((val: IEvent): JSX.Element => {
    let text;
    if (val?.payload) {
      try {
        text = JSON.parse(val?.payload).comment;
      } catch {
        console.log('Payload is not in JSON format');
      }
    }

    return (
      <>
        {text && '.'}
        {text && (
          <>
            <br />
            {text}
          </>
        )}
      </>
    );
  }, []);

  const eventType = useCallback(
    (val: IEvent): JSX.Element => (
      <span style={{ color: documentStatusColor(val) }}>
        {changeReportStatusName(startCase(toLower(val?.eventType)))}
      </span>
    ),
    []
  );

  // const content = (val: IEvent): JSX.Element => {
  //   return <div>{val.payload}</div>;
  // };

  const documentCreated = useCallback(
    (val: IEvent): JSX.Element => (
      <div className={s.eventLogItem}>
        {eventType(val)}
        {commentByEvent(val)}
      </div>
    ),
    [commentByEvent, eventType]
  );

  const documentUpdated = (): JSX.Element => <div>Document Information Updated</div>;

  const documentReviewersUpdated = (): JSX.Element => <div>Document Reviewers Updated</div>;

  const documentReviewRequested = useCallback(
    (val: IEvent): JSX.Element => (
      <div>
        {eventType(val)} for {val?.subject?.user?.name || 'Unknown'}
      </div>
    ),
    [eventType]
  );

  const documentVersionDeleted = useCallback(
    (val: IEvent): JSX.Element => {
      let jsonPayload;
      if (val?.payload) {
        try {
          jsonPayload = JSON.parse(val?.payload);
        } catch {}
      }
      const version = jsonPayload?.version;

      if (jsonPayload?.reviewReversalReviewerUserIds?.length && jsonPayload) {
        if (!usersMapRequired) {
          setUsersMapRequired(true);
        }
      }

      return (
        <div className={s.eventLogItem}>
          {eventType(val)}
          {version ? (
            <>
              <br />
              Version: {getVersion({ version: { version } })}
            </>
          ) : undefined}
          {commentByEvent(val)}
          {val.version?.file?.originalFilename ? (
            <>
              <br />
              Filename: {val.version?.file?.originalFilename}
            </>
          ) : undefined}
          {jsonPayload?.reviewReversalReviewerUserIds?.length &&
          jsonPayload?.reviewReversalToStatus &&
          !!usersMap ? (
            <>
              <br />
              Review status reverted to "
              {capitalizeFirstLetter(
                (jsonPayload.reviewReversalToStatus as string).toLowerCase().replace('_', ' ')
              )}
              " for{' '}
            </>
          ) : undefined}
          {jsonPayload?.reviewReversalReviewerUserIds?.length && !!usersMap
            ? join(
                jsonPayload?.reviewReversalReviewerUserIds.map((id: string) => {
                  return usersMap[id] ? usersMap[id].name || usersMap[id].email : 'User ' + id;
                }),
                ', '
              )
            : undefined}
        </div>
      );
    },
    [commentByEvent, eventType, usersMap, usersMapRequired]
  );

  const documentVersionCreated = useCallback(
    (val: IEvent): JSX.Element => {
      return (
        <div className={s.eventLogItem}>
          {eventType(val)}
          {val.version?.file?.originalFilename ? (
            <>
              <br />
              Filename: {val.version?.file?.originalFilename}
            </>
          ) : undefined}
          {commentByEvent(val)}
        </div>
      );
    },
    [commentByEvent, eventType]
  );

  const documentUserEvent = useCallback(
    (val: IEvent): JSX.Element => {
      return (
        <div className={s.eventLogItem}>
          {eventType(val)}
          {commentByEvent(val)}
        </div>
      );
    },
    [commentByEvent, eventType]
  );

  const documentClientApprovalStatusChanged = useCallback(
    (val: IEvent): JSX.Element => {
      let jsonPayload;
      if (val?.payload) {
        try {
          jsonPayload = JSON.parse(val?.payload);
        } catch {}
      }
      const status =
        jsonPayload && jsonPayload.status
          ? 'status : ' + startCase(toLower(jsonPayload.status))
          : undefined;
      const isRequired =
        jsonPayload && jsonPayload.isRequired !== undefined
          ? 'Required set to ' + jsonPayload.isRequired.toString()
          : undefined;
      return (
        <div>
          {eventType(val)} <br />
          {status ? (
            <>
              {status} <br />{' '}
            </>
          ) : undefined}
          {isRequired ? (
            <>
              {isRequired} <br />{' '}
            </>
          ) : undefined}
        </div>
      );
    },
    [eventType]
  );

  const documentOwnerChanged = useCallback(
    (val: IEvent): JSX.Element => {
      // val.payload is like {"ownerUserId":"294"}
      return <div>{eventType(val)}</div>;
    },
    [eventType]
  );

  const documentMonetaryTtransactionBreakdownChanged = useCallback(
    (val: IEvent): JSX.Element => {
      // val.payload is like {"monetaryTransactionBreakdownsToAdd":[{"value":1111111,"startTimestamp":"2020-01-31T23:00:00.000Z"}]}
      return <div>{eventType(val)}</div>;
    },
    [eventType]
  );

  const documentReviewRecalled = useCallback(
    (val: IEvent): JSX.Element => (
      <div>
        {eventType(val)} for {val?.author?.user?.name}
      </div>
    ),
    [eventType]
  );

  const documentReviewResent = useCallback(
    (val: IEvent): JSX.Element => {
      return (
        <div>
          {eventType(val)} for {val?.subject?.user?.name}
        </div>
      );
    },
    [eventType]
  );

  const documentCommented: (val: IEvent) => JSX.Element = useCallback(
    (val: IEvent): JSX.Element => {
      let text;
      let quoteEventId;
      if (val.payload) {
        try {
          text = JSON.parse(val.payload).comment;
          quoteEventId = JSON.parse(val.payload).refEventId;
        } catch {}
      }
      return showCommentRef.current && showCommentRef.current(val, text, quoteEventId);
    },
    []
  );

  const content = useCallback(
    (val: IEvent): JSX.Element => {
      switch (val.eventType) {
        case DocumentEventType.DOCUMENT_CREATED:
          return documentCreated(val);

        case DocumentEventType.DOCUMENT_VERSION_DELETED:
          return documentVersionDeleted(val);

        case DocumentEventType.DOCUMENT_VERSION_CREATED:
          return documentVersionCreated(val);

        case DocumentEventType.DOCUMENT_COMMENTED:
        case DocumentEventType.DOCUMENT_COMMENT_REPLIED:
          return documentCommented(val);

        case DocumentEventType.DOCUMENT_OBSERVER_NOTIFICATION_SENT:
        case DocumentEventType.DOCUMENT_REVIEW_REQUESTED:
          return documentReviewRequested(val);

        case DocumentEventType.DOCUMENT_REVIEW_RESENT:
          return documentReviewResent(val);

        case DocumentEventType.DOCUMENT_PARTY_REVIEW_STATUS_CHANGED:
        case DocumentEventType.DOCUMENT_CLIENT_APPROVAL_STATUS_CHANGED:
          return documentClientApprovalStatusChanged(val);

        case DocumentEventType.DOCUMENT_UPDATED:
          return documentUpdated();

        case DocumentEventType.DOCUMENT_REVIEWERS_UPDATED:
          return documentReviewersUpdated();

        case DocumentEventType.DOCUMENT_REVIEW_RECALLED:
          return documentReviewRecalled(val);

        case DocumentEventType.DOCUMENT_REVIEW_APPROVED:
        case DocumentEventType.DOCUMENT_ALL_REVIEWS_COMPLETED:
        case DocumentEventType.DOCUMENT_REVIEW_DISAPPROVED:
        case DocumentEventType.DOCUMENT_REVIEW_POSTPONED:
        case DocumentEventType.DOCUMENT_ARCHIVED:
        case DocumentEventType.DOCUMENT_ARCHIVE_INITIATED:
        case DocumentEventType.DOCUMENT_ARCHIVE_DOCUMENT_STORED:
        case DocumentEventType.DOCUMENT_CANCELED:
          return documentUserEvent(val);

        case DocumentEventType.DOCUMENT_FILE_DELETED:
          return <div>Support Document Deleted</div>;
        case DocumentEventType.DOCUMENT_FILE_UPLOADED:
          return <div>Support Document Uploaded</div>;

        case DocumentEventType.DOCUMENT_OWNER_CHANGED:
          return documentOwnerChanged(val);

        case DocumentEventType.DOCUMENT_MONETARY_TRANSACTION_BREAKDOWN_CHANGE:
          return documentMonetaryTtransactionBreakdownChanged(val);

        case DocumentEventType.DOCUMENT_DOCUSIGN_ENVELOPE_STATUS_CHANGED:
        case DocumentEventType.DOCUMENT_DOCUSIGN_CREATED:
        case DocumentEventType.DOCUMENT_DOCUSIGN:
          return (
            <div>
              {eventType(val)}
              {docusignEventInfo(val)}
            </div>
          );

        default:
          return <div>{eventType(val)}</div>;
      }
    },
    [
      eventType,
      documentClientApprovalStatusChanged,
      documentCommented,
      documentCreated,
      documentMonetaryTtransactionBreakdownChanged,
      documentOwnerChanged,
      documentReviewRecalled,
      documentReviewRequested,
      documentReviewResent,
      documentUserEvent,
      documentVersionCreated,
      documentVersionDeleted,
      docusignEventInfo,
    ]
  );

  const filterValues = useCallback((): IEvent[] => {
    const result = events;

    return result;
  }, [events]);

  const showComment = useCallback((val: IEvent, text: string, quoteEventId?: string) => {
    return (
      <div>
        <div
          className={s.eventLogItem}
          style={{
            border: '1px solid #ccc',
            padding: '11px 10px',
            borderRadius: '3px',
            width: '100%',
          }}
        >
          {text}
        </div>
      </div>
    );
  }, []);

  useEffect(() => {
    showCommentRef.current = showComment;
  }, [showComment]);

  const user = (data: any): string =>
    data?.author
      ? `${data?.author?.persona ? data?.author?.persona : ''} ${data?.author?.user?.name}`
      : 'Undefined Author';

  const parsedEvents = useMemo(() => {
    return filterValues()
      .slice()
      .sort((a: any, b: any) => +new Date(b?.createdAt) - +new Date(a?.createdAt))
      .reverse()
      .map((ev: IEvent) => {
        const div = document.createElement('div');
        const root = createRoot(div);
        flushSync(() => {
          root.render(
            <EventCommentGanttLogItem
              event={ev}
              showCommentRef={showCommentRef}
              usersMap={usersMap}
            />
          );
        });
        return {
          id: uuid(),
          ev,
          content: content(ev),
          payload: JSON.parse(ev.payload),
          htmlContentDivs: [div],
          title: `${changeReportStatusName(startCase(toLower(ev.eventType)))}${
            ev?.subject?.user?.name ? ' for ' + ev?.subject?.user?.name : ''
          } (${user(ev)}, ${getVersion(ev)}, ${formatTime(ev?.createdAt)})`,
        };
      });
  }, [filterValues, content, usersMap]);

  return {
    parsedEvents,
  };
};
