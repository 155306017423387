import React, { createContext, FC, useContext, useEffect, useState } from 'react';

export interface IContextState {
  myTask: boolean;
}

export interface IContextActions {
  setMyTask: any;
}

const initialState: IContextState = {
  myTask: false,
};

const ComponentContext = createContext<IContextState & Partial<IContextActions>>(initialState);

interface IProviderProps {
  children: any;
}

export const Provider: FC<IProviderProps> = ({ children }) => {
  const [myTask, setMyTask] = useState(sessionStorage.getItem('myTask') !== '0');

  useEffect(() => {
    sessionStorage.setItem('myTask', myTask ? '1' : '0');
  }, [myTask]);

  return (
    <ComponentContext.Provider
      value={{
        myTask,
        setMyTask,
      }}
    >
      {children}
    </ComponentContext.Provider>
  );
};

export const useComponentContext = () => useContext(ComponentContext);
