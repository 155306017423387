import React, { FC, useMemo } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Frame } from './components/Frame/Frame';
import { TableSection } from './components/TableSection/TableSection';
import { Provider as TableSectionProvider } from './components/TableSection/TableSectionContext';
import { TreeSection } from './components/TreeSection/TreeSection';
import s from './style.module.scss';
import { useComponentContext } from './TreeTableContext';
import { Breadcrumbs } from './components/Breadcrumbs/Breadcrumbs';
import { useLegalFolder } from './components/Breadcrumbs/legalFolderHook';
import { useProjectFolder } from './components/Breadcrumbs/projectFolderHook';
import { useProjectDocument } from './components/Breadcrumbs/projectDocumentHook';
import { Dashboard } from './components/Dashboard/Dashboard';
import { NoAccess } from 'template/NoAccess/NoAccess';
import { FormControlLabel, Switch } from '@mui/material';

interface ITreeTableProps {
  projectFolderInvalid?: boolean;
  projectFolderDocumentInvalid?: boolean;
}

export const TreeTable: FC<ITreeTableProps> = ({
  projectFolderInvalid,
  projectFolderDocumentInvalid,
}) => {
  const {
    selectedFolder,
    initTab,
    initDocumentTab,
    initProjectFoldersTab,
    setActiveTab,
    onGoHome,
    onSelectLegalFolder,
    onViewProjectFolder,
    onSelectProjectDocument,
    setDocumentViewTab,
    setProjectFoldersViewTab,
    showWellcome,
    myTask,
    setMyTask,
  } = useComponentContext();

  const { legalFolderName, error: legalFolderInvalid } = useLegalFolder({
    id: selectedFolder?.legalFolderId,
  });

  const { projectFolderName } = useProjectFolder({
    id: selectedFolder?.projectFolderId,
  });

  const { projectDocumentName } = useProjectDocument({
    id: selectedFolder?.projectDocumentId,
  });

  const noAccess = useMemo(() => {
    if (projectFolderInvalid) {
      return <NoAccess name="Project Folder"></NoAccess>;
    }
    if (projectFolderDocumentInvalid) {
      return <NoAccess name="Project Folder Document"></NoAccess>;
    }
    if (selectedFolder?.legalFolderId && legalFolderInvalid) {
      return <NoAccess name="Legal Folder"></NoAccess>;
    }
  }, [selectedFolder, legalFolderInvalid, projectFolderInvalid, projectFolderDocumentInvalid]);

  return (
    <div>
      <Frame>
        <Grid container padding={0} spacing={4} className={s.treetable}>
          <Grid xs={12} sm={8} className={s.treeview}>
            <Breadcrumbs
              initTab={initTab}
              initDocumentTab={initDocumentTab}
              initProjectFoldersTab={initProjectFoldersTab}
              onClickHome={onGoHome!}
              legalFolder={
                selectedFolder?.legalFolderId
                  ? { id: selectedFolder.legalFolderId, name: legalFolderName }
                  : initTab === 'LF'
                  ? {}
                  : undefined
              }
              projectFolder={
                selectedFolder?.legalFolderId
                  ? selectedFolder?.projectFolderId
                    ? { id: selectedFolder.projectFolderId, name: projectFolderName }
                    : initTab === 'PF'
                    ? {}
                    : undefined
                  : undefined
              }
              document={
                selectedFolder?.legalFolderId
                  ? selectedFolder?.projectFolderId
                    ? selectedFolder?.projectDocumentId
                      ? { id: selectedFolder.projectDocumentId, name: projectDocumentName }
                      : initTab === 'D' && initDocumentTab === 'new'
                      ? {}
                      : undefined
                    : undefined
                  : undefined
              }
              onLegalFolderClick={() =>
                onSelectLegalFolder &&
                selectedFolder &&
                onSelectLegalFolder(selectedFolder.legalFolderId)
              }
              onProjectFolderClick={() => {
                onViewProjectFolder &&
                  selectedFolder &&
                  selectedFolder.projectFolderId &&
                  onViewProjectFolder(selectedFolder.legalFolderId, selectedFolder.projectFolderId);
              }}
              onDocumentClick={() => {
                selectedFolder &&
                  selectedFolder.projectDocumentId &&
                  onSelectProjectDocument &&
                  onSelectProjectDocument(selectedFolder.projectDocumentId);
              }}
            ></Breadcrumbs>
          </Grid>
          <Grid
            xs={12}
            sm={4}
            style={{ display: 'flex', justifyContent: 'end', position: 'relative' }}
          >
            <div style={{ position: 'absolute', top: '50%', translate: '0 -50%' }}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={myTask}
                    onChange={() => {
                      setMyTask!(!myTask);
                    }}
                  ></Switch>
                }
                label="My Task"
              />
            </div>
          </Grid>

          <Grid xs={12} md={3} xl={2} className={s.treeview}>
            <Grid container padding={0} spacing={2} className={s.treeviewbox}>
              <Grid xs={12}>
                <TreeSection></TreeSection>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} md={9} xl={10} className={s.tableview}>
            <Grid container padding={0} spacing={2} className={s.tableviewbox}>
              <Grid xs={12} style={{ padding: '0px' }}>
                {noAccess ||
                  (showWellcome ? (
                    <Dashboard></Dashboard>
                  ) : (
                    <TableSectionProvider
                      legalFolderId={selectedFolder?.legalFolderId}
                      projectFolderId={selectedFolder?.projectFolderId}
                      projectDocumentId={selectedFolder?.projectDocumentId}
                      newProjectDocumentTs={selectedFolder?.newProjectDocumentTs}
                      showTab={
                        initTab
                          ? initTab
                          : !!selectedFolder?.projectFolderId || !selectedFolder?.legalFolderId
                          ? 'D'
                          : 'LF'
                      }
                      setActiveTab={setActiveTab!}
                      setDocumentViewTab={setDocumentViewTab!}
                      showDocumentTab={initDocumentTab || 'list'}
                      setProjectFoldersViewTab={setProjectFoldersViewTab!}
                      showProjectFoldersTab={initProjectFoldersTab || 'list-pf'}
                    >
                      <TableSection></TableSection>
                    </TableSectionProvider>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Frame>
    </div>
  );
};
