import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Select,
  useTheme,
  useMediaQuery,
  Tooltip,
  IconButton,
  Chip,
  Link,
} from '@mui/material';

import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from '@mui/icons-material';

import Grid from '@mui/material/Unstable_Grid2';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { keys } from 'lodash';

import '../DocumentViewForm/index.scss';
import s from '../DocumentViewForm/style.module.scss';

import { useHistory } from 'react-router-dom';

import EasyAutoComplete from 'components/EasyAutoComplete';
import { Contacts } from '../../../Contacts/Contacts';

import { useComponentContext } from '../../../../LegalFolderDocumentContext';

import { useEffect, useMemo, useState, forwardRef, useCallback } from 'react';
import { useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client';

import {
  DOCUMENT_MOVE,
  GET_DOCUMENT_TYPES,
  GET_PROJECT_FOLDER_DOCUMENTS,
} from 'graphql/legalFolders/documents';
import { documentTypes } from 'graphql/legalFolders/types/documentTypes';
import { getLegalFolderDocuments } from 'graphql/legalFolders/types/getLegalFolderDocuments';
import { FileDarkIcon } from 'images/icons';

import { containerIndemnity } from 'constants/indemnities';
import { paths } from 'constants/index';

import {
  DocumentIndemnity,
  MonetaryTransactionType,
} from 'graphql/legalFolders/types/graphql-types';

import { apolloErrorHandler } from 'utils/apolloErrorHandler';
import { useUI } from 'contexts/UiContext';
import { Reviewers } from '../../../Reviewers/Reviewers';
import { IReviewer } from '../../../Reviewers/interfaces';
import { SelectProject } from 'template/ProjectFolder/components/SelectProject/SelectProject';
import { SelectDivision } from '../SelectDivision/SelectDivision';
import { MonetaryTransactions } from '../MonetaryTransactions/MonetaryTransactions';

import { SelectMultipleOwners } from '../SelectMultipleOwners/SelectMultipleOwners';
import { MAX_OWNERS, NAME_LENGTH_LIMIT } from 'constants/config';
import { Clear } from '@mui/icons-material';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { capitalizeFirstLetter } from 'utils/formats';
import { reviewerReminderPeriods } from 'constants/reviewerReminderPeriods';
import { SelectChangeEvent } from '@mui/material/Select';
import { CurrencyInputField } from 'components/CurrencyInputField/CurrencyInputField';
import { PickersActionBarAction } from '@mui/x-date-pickers/PickersActionBar/PickersActionBar';
import { IHierarchySortResultItem, hierarchySort } from 'utils/hierarchySort';
import { Observers } from 'template/LegalFolderDocument/components/Observers/Observers';
import { IObserver } from 'template/LegalFolderDocument/components/Observers/interfaces';

import { useCurrentDbUser } from 'hooks/currentDbUserHook';
import { DriveFileMove as MoveIcon } from '@mui/icons-material';

import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { tryUpdateProcedure } from 'utils/apollo';
import { DialogForm } from 'components/DialogForm/DialogForm';
import { MoveDocument } from 'components/MoveDocument/MoveDocument';

interface IGeneralListItem {
  id: string;
  name: string;
}

export const DocumentViewFormFields = forwardRef<HTMLDivElement>((props, ref) => {
  const client = useApolloClient();
  const [moveDocumentMutation] = useMutation(DOCUMENT_MOVE);
  const { confirmRequest } = useFormChangedDialogContext();

  const { user } = useCurrentDbUser();

  const isLegalAdmin = useMemo(() => {
    return !!user?.isContractsAdmin;
  }, [user]);

  const [showDisabledReviewers, setShowDisabledReviewers] = useState(false);
  const [showDisabledObservers, setShowDisabledObservers] = useState(false);

  const history = useHistory();
  const { addSnackbar } = useUI();

  const [documentTypes, setDocumentTypes] = useState<Array<IGeneralListItem>>();
  const [parentDocumentName, setParentDocumentName] = useState<string>();
  const [documentNamesList, setDocumentNameList] = useState<IHierarchySortResultItem[]>();

  const { data: documentTypesData, loading: documentTypesLoading } =
    useQuery<documentTypes>(GET_DOCUMENT_TYPES);

  const [
    loadLegalFolderDocuments,
    {
      called: loadLegalFolderDocumentsCalled,
      data: loadLegalFolderDocumentsData,
      loading: loadLegalFolderDocumentsLoading,
      refetch: loadLegalFolderDocumentsRefetch,
      error: loadLegalFolderDocumentsError,
    },
  ] = useLazyQuery<getLegalFolderDocuments>(GET_PROJECT_FOLDER_DOCUMENTS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (!loadLegalFolderDocumentsLoading && loadLegalFolderDocumentsError) {
      apolloErrorHandler(addSnackbar!)(loadLegalFolderDocumentsError);
    }
  }, [loadLegalFolderDocumentsLoading, loadLegalFolderDocumentsError, addSnackbar]);

  useEffect(() => {
    if (documentTypesData && !documentTypesLoading) {
      setDocumentTypes(
        documentTypesData.contract_documentTypes.map((documentType) => {
          return {
            id: documentType.id,
            name: `${documentType.name}`,
          };
        })
      );
    }
  }, [documentTypesData, documentTypesLoading]);

  const {
    projectFolderDocument,
    onAddNewContact,
    onSetLegalFolderDocument,
    setContact,
    deleteContact,
    onSelectProjectsChange,
    onSelectDivisionsChange,
    onSelectOwnersChange,
    onAddMonetaryTransaction,
    onRemoveMonetaryTransaction,
    onChangeMonetaryTransaction,
    onDocumentClick,
    projectLeaders,
    usersListAsReviewers,
  } = useComponentContext();

  const {
    name,
    showValidator,
    errors,
    startDate,
    endDate,
    notes,
    specialInstructions,
    contacts,
    projectFolderName,
    projectFolderId,
    legalFolderId,
    selectedDocumentType,
    selectedParentDocument,
    selectedReviewersReminderPeriod,
    isOriginParty,
    indemnity,
    includesIndemnity,
    customIndemnity,
    id,
    childDocuments,
    isPartyReviewRequired,
    reviewers,
    observers,
    projects,
    divisions,
    outsideCounsel,
    monetaryTransactionValue,
    monetaryTransactionBreakdowns,
    owners,
    managers,
    monetaryTransactionType,
    currentUserName,
    createdAt,
    updatedAt,
    container,
  } = projectFolderDocument;

  const displayReviewersReminderPeriods = useMemo(() => {
    if (
      !selectedReviewersReminderPeriod ||
      reviewerReminderPeriods.find((period) => selectedReviewersReminderPeriod?.id === period.id)
    ) {
      return reviewerReminderPeriods;
    }
    return [selectedReviewersReminderPeriod, ...reviewerReminderPeriods].sort(
      (a, b) => +a.id - +b.id
    );
  }, [selectedReviewersReminderPeriod]);

  useEffect(() => {
    if (projectFolderId && projectFolderId !== '') {
      const variables = {
        take: 20,
        skip: 0,
        sort: [{ column: 'NAME', order: 'ASC' }],
        filter: legalFolderId
          ? { legalFolderIds: [legalFolderId] }
          : { projectFolderId: [projectFolderId] },
      };

      if (loadLegalFolderDocumentsCalled) {
        loadLegalFolderDocumentsRefetch!(variables);
      } else {
        loadLegalFolderDocuments({ variables });
      }
    }
  }, [
    loadLegalFolderDocuments,
    loadLegalFolderDocumentsRefetch,
    loadLegalFolderDocumentsCalled,
    parentDocumentName,
    projectFolderId,
    legalFolderId,
  ]);

  const usedDocuments = useMemo(
    () => [id, ...childDocuments.map((childDocument) => childDocument.id)],
    [id, childDocuments]
  );

  useEffect(() => {
    if (loadLegalFolderDocumentsData && !loadLegalFolderDocumentsLoading) {
      setDocumentNameList(() => {
        const lfIds: string[] = [];
        const projectFolders = loadLegalFolderDocumentsData.contract_documents
          .filter((document) => !usedDocuments.includes(document.id))
          .filter((document) => {
            if (!document.container?.id) {
              return false;
            }
            const included = lfIds.includes(document.container.id);
            if (!included) {
              lfIds.push(document.container.id);
            }
            return !included;
          })
          .map((document) => ({
            id: 'pf_' + document.container!.id,
            name: document.container!.name,
            key: document.container!.name,
            order: 0,
            disabled: true,
            parent: undefined,
          }));

        const retVal = hierarchySort(
          [
            ...projectFolders,
            ...(loadLegalFolderDocumentsData.contract_documents
              ?.map((document) => ({
                id: document.id,
                name: document.name,
                key: document.name,
                order: 0,
                disabled: false,
                parent: 'pf_' + document.container?.id,
              }))
              .filter((document) => !usedDocuments.includes(document.id)) || []),
          ].sort((a, b) => a.name.localeCompare(b.name))
        );

        return retVal;
      });
    }
  }, [loadLegalFolderDocumentsData, loadLegalFolderDocumentsLoading, usedDocuments]);

  const stakeholderListChanged = useCallback(
    (items: IReviewer[]): void => {
      onSetLegalFolderDocument!((oldLegalFolder) => {
        return {
          ...oldLegalFolder,
          reviewers: items,
        };
      });
    },
    [onSetLegalFolderDocument]
  );

  const stakeholderObserversListChanged = useCallback(
    (items: IObserver[]): void => {
      onSetLegalFolderDocument!((oldLegalFolder) => {
        return {
          ...oldLegalFolder,
          observers: items,
        };
      });
    },
    [onSetLegalFolderDocument]
  );

  const ArrowIcon = (): JSX.Element => {
    if (!showDisabledReviewers) {
      return (
        <ArrowDropDownIcon
          fontSize="large"
          style={{ marginLeft: '16px', verticalAlign: 'middle' }}
        />
      );
    }
    return (
      <ArrowDropUpIcon fontSize="large" style={{ marginLeft: '16px', verticalAlign: 'middle' }} />
    );
  };

  const ObserversArrowIcon = (): JSX.Element => {
    if (!showDisabledObservers) {
      return (
        <ArrowDropDownIcon
          fontSize="large"
          style={{ marginLeft: '16px', verticalAlign: 'middle' }}
        />
      );
    }
    return (
      <ArrowDropUpIcon fontSize="large" style={{ marginLeft: '16px', verticalAlign: 'middle' }} />
    );
  };

  const toggleVisible = (e: any): void => {
    e.preventDefault();
    e.cancelBubble = true;
    e.stopPropagation();
    setShowDisabledReviewers((old: boolean) => !old);
  };

  const toggleVisibleObservers = (e: any): void => {
    e.preventDefault();
    e.cancelBubble = true;
    e.stopPropagation();
    setShowDisabledObservers((old: boolean) => !old);
  };

  const getHideShowTitle = (): string =>
    showDisabledReviewers ? 'hide disabled' : 'show disabled';
  const getHideShowObserversTitle = (): string =>
    showDisabledObservers ? 'hide disabled' : 'show disabled';

  // const indemnityRequired = ['Master Services Agreement', 'Engagement Letter', 'Contract'].includes( selectedDocumentType?.name || '' );
  const indemnityRequired = false;

  const containerIndemnityKeys = useMemo(() => {
    const keysList = keys(containerIndemnity);
    if (indemnityRequired && indemnity !== 'NONE' && !!indemnity) {
      return keysList.filter((key) => key !== 'NONE');
    }
    return keysList;
  }, [indemnityRequired, indemnity]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const documentsHierarchy = useMemo(() => {
    if (!documentNamesList) return [];

    return [...documentNamesList];
  }, [documentNamesList]);

  const [openMoveDocumentDialog, setOpenMoveDocumentDialog] = useState(false);
  const [selectedLegalFolder, setSelectedLegalFolder] = useState<
    { id: string; name: string } | undefined
  >();
  const [selectedProjectFolder, setSelectedProjectFolder] = useState<
    | {
        id: string;
        name: string;
      }
    | undefined
  >();

  const currentLegalFolder = useMemo(() => {
    return container?.legalFolder
      ? { id: container?.legalFolder.id, name: container?.legalFolder.name }
      : undefined;
  }, [container]);

  const onSelectLegalFolderChange = useCallback((legalFolder: IGeneralListItem) => {
    setSelectedLegalFolder(legalFolder);
    setSelectedProjectFolder(undefined);
  }, []);

  const onMoveClick = useCallback(() => {
    confirmRequest!((confirmed) => {
      confirmed && setOpenMoveDocumentDialog(true);
    });
  }, [confirmRequest]);

  const onCloseMoveDocumentDialog = useCallback(() => {
    setSelectedLegalFolder(currentLegalFolder);
    setSelectedProjectFolder(undefined);
    setOpenMoveDocumentDialog(false);
  }, [currentLegalFolder]);

  useEffect(() => {}, []);

  useEffect(() => {
    setSelectedLegalFolder(currentLegalFolder);
  }, [currentLegalFolder]);

  const moveDocument = useCallback(async () => {
    setOpenMoveDocumentDialog(false);
    const { result, isError, errors } = await tryUpdateProcedure({
      mutation: () =>
        moveDocumentMutation({
          variables: {
            documentId: id,
            destinationContainerId: selectedProjectFolder?.id,
          },
        }),
      parseResult: (data: any) => {
        return data;
      },
    });
    if (result && !isError) {
      await client.resetStore();
      history.push(paths.treeViewClient.TREEVIEW_DOCUMENT.replace(':id', id));
      addSnackbar && addSnackbar({ severity: 'success', text: 'Success' });
      addSnackbar &&
        addSnackbar({
          severity: 'info',
          text: 'Current Project Folder has changed!',
        });
    } else {
      addSnackbar && addSnackbar({ severity: 'error', text: errors ? errors.join('. ') : 'Error' });
    }
  }, [client, history, id, moveDocumentMutation, addSnackbar, selectedProjectFolder]);

  return (
    <>
      <DialogForm
        dialogTitle={'Move Document'}
        onClose={onCloseMoveDocumentDialog}
        open={openMoveDocumentDialog}
        maxWidth="md"
      >
        <div style={{ padding: '10px 40px 30px 30px' }}>
          <MoveDocument
            onSelectLegalFolderChange={onSelectLegalFolderChange}
            selectedLegalFolder={selectedLegalFolder}
            onSelectProjectFolderChange={setSelectedProjectFolder}
            selectedProjectFolder={selectedProjectFolder}
            currentContainer={container}
            cancelHandler={onCloseMoveDocumentDialog}
            confirmHandler={moveDocument}
          />
        </div>
      </DialogForm>
      <Container maxWidth="md" ref={ref} style={!matches ? { padding: '0px' } : undefined}>
        <Grid container padding={0} spacing={2} className={s.dataBlock}>
          <Grid xs={12}>
            <Box m={5} />

            <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
              <>
                <Grid xs={5} lg={4}>
                  <Typography variant="body2" className="label-title">
                    Document name:
                  </Typography>
                </Grid>
                <Grid xs={7} lg={8}>
                  <Tooltip
                    title={
                      <div>
                        Suggested Document Name:
                        <br />
                        {'Client Name<space>Date<space>Detail'}
                      </div>
                    }
                    placement="top-start"
                  >
                    <TextField
                      variant="outlined"
                      size="small"
                      value={name}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onSetLegalFolderDocument!((oldLegalFolder) => {
                          return {
                            ...oldLegalFolder,
                            name: e.target.value,
                          };
                        });
                      }}
                      fullWidth
                      error={showValidator && !!errors?.name}
                      inputProps={{ maxLength: NAME_LENGTH_LIMIT }}
                    />
                  </Tooltip>
                </Grid>

                <Grid xs={5} lg={4}>
                  <Typography variant="body2" className="label-title">
                    Project folder:
                  </Typography>
                </Grid>
                <Grid xs={7} lg={8}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flexGrow: 1 }}>
                      <TextField
                        variant="outlined"
                        size="small"
                        value={projectFolderName}
                        onChange={() => {}}
                        fullWidth
                        error={showValidator && !!errors?.projectFolderName}
                        disabled={true}
                      />
                    </div>
                    {isLegalAdmin ? (
                      <div
                        onClick={debounce(onMoveClick, DEBOUNCE_TIMEOUT)}
                        style={{ cursor: 'pointer', paddingLeft: '10px' }}
                      >
                        <Tooltip title={`Change Project Folder`} disableInteractive={true}>
                          <IconButton size="small">
                            <MoveIcon style={{ color: '#006ad4' }} fontSize="medium"></MoveIcon>
                          </IconButton>
                        </Tooltip>
                      </div>
                    ) : undefined}
                  </div>
                </Grid>

                <Grid xs={5} lg={4}>
                  <Typography variant="body2" className="label-title">
                    Owners:
                  </Typography>
                </Grid>
                <Grid xs={7} lg={8}>
                  {id.length ? (
                    <SelectMultipleOwners
                      selectedItems={owners}
                      onSelectChange={onSelectOwnersChange}
                      error={
                        showValidator && (!owners || !owners.length || owners.length > MAX_OWNERS)
                      }
                    ></SelectMultipleOwners>
                  ) : (
                    <TextField
                      variant="outlined"
                      size="small"
                      value={currentUserName || ''}
                      onChange={() => {}}
                      fullWidth
                      error={false}
                      disabled={true}
                    />
                  )}
                </Grid>

                {managers?.length && id.length ? (
                  <>
                    <Grid xs={5} lg={4}>
                      <Typography variant="body2" className="label-title">
                        Admins:
                      </Typography>
                    </Grid>
                    <Grid xs={7} lg={8}>
                      {managers.map((manager) => {
                        return (
                          <div key={manager.id} style={{ display: 'inline-block', margin: '3px' }}>
                            <Tooltip
                              title={
                                <>
                                  {capitalizeFirstLetter(manager.persona?.toLowerCase())}
                                  <br />
                                  {manager.email}
                                </>
                              }
                            >
                              <Chip label={manager.name} />
                            </Tooltip>
                          </div>
                        );
                      })}
                    </Grid>
                  </>
                ) : undefined}

                <Grid xs={5} lg={4}>
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Typography variant="body2" className="label-title">
                      Type:
                    </Typography>
                    <div style={{ padding: '0 2px' }}>
                      <Typography variant="body2" className="label-title-desc">
                        (required)
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid xs={7} lg={8}>
                  <EasyAutoComplete
                    key={`owner-${selectedDocumentType?.id}`}
                    items={documentTypes || []}
                    label=""
                    textFieldStyle="outlined"
                    optionsLabel="name"
                    selected={selectedDocumentType}
                    selectedChanged={(value: IGeneralListItem) => {
                      onSetLegalFolderDocument!((oldLegalFolderDocument) => {
                        return {
                          ...oldLegalFolderDocument,
                          selectedDocumentType: value,
                        };
                      });
                    }}
                    error={showValidator && !!errors?.selectedDocumentType}
                    getOptionSelected={(option: any, value: any) => {
                      return option.id === value.id;
                    }}
                  />
                </Grid>
                {id.length ? (
                  <>
                    <Grid xs={5} lg={4}>
                      <Typography variant="body2" className="label-title">
                        Created Date:
                      </Typography>
                    </Grid>
                    <Grid xs={7} lg={8}>
                      {createdAt
                        ? new Date(createdAt).toLocaleDateString('en-EN', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          })
                        : '--'}
                    </Grid>

                    <Grid xs={5} lg={4}>
                      <Typography variant="body2" className="label-title">
                        Last Modified Date:
                      </Typography>
                    </Grid>
                    <Grid xs={7} lg={8}>
                      {updatedAt
                        ? new Date(updatedAt).toLocaleDateString('en-EN', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          })
                        : '--'}
                    </Grid>
                  </>
                ) : undefined}
              </>
            </Grid>
          </Grid>
        </Grid>

        <Box m={3} />

        <Grid container padding={0} spacing={2} className={s.dataBlock}>
          <Grid xs={12}>
            <Divider />
            <Box m={3} />
          </Grid>
        </Grid>

        <Grid container padding={0} spacing={2} className={s.dataBlock}>
          <Grid xs={12}>
            <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
              <Grid xs={5} lg={4}>
                <Typography variant="body2" className="label-title">
                  Parent document:
                </Typography>
              </Grid>
              <Grid xs={7} lg={8}>
                <Grid container padding={0}>
                  <Grid xs={1}>
                    <div
                      title="View parent document"
                      onClick={debounce(() => {
                        if (selectedParentDocument) {
                          onDocumentClick
                            ? onDocumentClick(selectedParentDocument?.id)
                            : history.push(
                                paths.client.PROJECT_FOLDER_DOCUMENT_ID.replace(
                                  ':documentId',
                                  selectedParentDocument?.id
                                )
                              );
                        }
                      }, DEBOUNCE_TIMEOUT)}
                      style={{ cursor: 'pointer' }}
                    >
                      <FileDarkIcon className={s.documentIcon}></FileDarkIcon>
                    </div>
                  </Grid>
                  <Grid xs={10}>
                    <EasyAutoComplete
                      key={`owner-${selectedParentDocument?.id}`}
                      items={documentsHierarchy || []}
                      label=""
                      textFieldStyle="outlined"
                      optionsLabel="name"
                      selected={selectedParentDocument}
                      onInputChange={(event: object, value: string, reason: string) => {
                        setParentDocumentName(value);
                      }}
                      selectedChanged={(value: IGeneralListItem) => {
                        onSetLegalFolderDocument!((oldLegalFolderDocument) => {
                          return {
                            ...oldLegalFolderDocument,
                            selectedParentDocument: value,
                          };
                        });
                      }}
                      error={showValidator && !!errors?.selectedParentDocument}
                      getOptionSelected={(option: any, value: any) => {
                        return option.id === value.id;
                      }}
                      showHierarchyLevel={true}
                    />
                  </Grid>
                  <Grid xs={1}>
                    <div style={{ cursor: 'pointer', textAlign: 'end' }}>
                      <Tooltip title="Remove Parent Document">
                        <IconButton
                          size="small"
                          onClick={debounce(() => {
                            onSetLegalFolderDocument!((oldLegalFolderDocument) => {
                              return {
                                ...oldLegalFolderDocument,
                                selectedParentDocument: undefined,
                              };
                            });
                          }, DEBOUNCE_TIMEOUT)}
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={5} lg={4}>
                <Typography variant="body2" className="label-title">
                  Child documents:
                </Typography>
              </Grid>
              <Grid xs={7} lg={8}>
                {!childDocuments?.length ? (
                  'The child documents list is empty. This document can be added as a parent to other documents.'
                ) : (
                  <Grid container padding={0}>
                    {childDocuments.map((childDocument) => {
                      return (
                        <div key={childDocument.id} style={{ display: 'contents' }}>
                          <Grid xs={1}>
                            <div
                              title="View child document"
                              onClick={debounce(() => {
                                onDocumentClick
                                  ? onDocumentClick(childDocument?.id)
                                  : history.push(
                                      paths.client.PROJECT_FOLDER_DOCUMENT_ID.replace(
                                        ':documentId',
                                        childDocument?.id
                                      )
                                    );
                              }, DEBOUNCE_TIMEOUT)}
                              style={{ cursor: 'pointer' }}
                            >
                              <FileDarkIcon className={s.documentIcon}></FileDarkIcon>
                            </div>
                          </Grid>
                          <Grid xs={11}>{childDocument.name}</Grid>
                        </div>
                      );
                    })}
                  </Grid>
                )}
              </Grid>

              <Grid xs={5} lg={4}>
                <Typography variant="body2" className="label-title">
                  Projects Assigned:
                </Typography>
              </Grid>
              <Grid xs={7} lg={8}>
                <SelectProject
                  onSelectChange={onSelectProjectsChange}
                  selectedItems={projects}
                  projectFolderId={projectFolderId}
                ></SelectProject>
              </Grid>

              <Grid xs={5} lg={4}>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Typography variant="body2" className="label-title">
                    Divisions:
                  </Typography>
                  <div style={{ padding: '0 2px' }}>
                    <Typography variant="body2" className="label-title-desc">
                      (required)
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid xs={7} lg={8}>
                <SelectDivision
                  onSelectChange={onSelectDivisionsChange}
                  selectedItems={divisions}
                  error={showValidator && !divisions.length}
                ></SelectDivision>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box m={3} />

        <Grid container padding={0} spacing={2} className={s.dataBlock}>
          <Grid xs={12}>
            <Divider />
            <Box m={3} />
          </Grid>
        </Grid>

        <Grid container padding={0} spacing={2} className={s.dataBlock}>
          <Grid xs={12}>
            <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
              <Grid xs={5} lg={4}>
                <Typography variant="body2" className="label-title">
                  Origin:
                </Typography>
              </Grid>
              <Grid xs={7} lg={8}>
                <RadioGroup
                  row
                  aria-label="isOriginParty"
                  name="position"
                  value={isOriginParty ? 'Party' : 'D&M'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onSetLegalFolderDocument!((oldLegalFolderDocument) => {
                      return {
                        ...oldLegalFolderDocument,
                        isOriginParty: e.target.value === 'Party',
                      };
                    });
                  }}
                >
                  <FormControlLabel value="D&M" control={<Radio color="primary" />} label="D&M" />
                  <FormControlLabel
                    value="Party"
                    control={<Radio color="primary" />}
                    label="Other Party"
                  />
                </RadioGroup>
              </Grid>

              <Grid xs={5} lg={4}>
                <Typography variant="body2" className="label-title">
                  Other Party approval:
                </Typography>
              </Grid>
              <Grid xs={7} lg={8}>
                <RadioGroup
                  row
                  aria-label="isOriginParty"
                  name="position"
                  value={isPartyReviewRequired ? '1' : '0'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onSetLegalFolderDocument!((oldLegalFolderDocument) => {
                      return {
                        ...oldLegalFolderDocument,
                        isPartyReviewRequired: e.target.value === '1',
                      };
                    });
                  }}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio color="primary" />}
                    label="Required"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio color="primary" />}
                    label="Not required"
                  />
                </RadioGroup>
              </Grid>

              <Grid xs={5} lg={4}>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Typography variant="body2" className="label-title">
                    Indemnity:
                  </Typography>
                  {indemnityRequired ? (
                    <div style={{ padding: '0 2px' }}>
                      <Typography variant="body2" className="label-title-desc">
                        (required)
                      </Typography>
                    </div>
                  ) : undefined}
                </div>
              </Grid>
              <Grid xs={7} lg={8}>
                <FormControl variant="outlined">
                  <Select
                    native
                    value={indemnity || includesIndemnity}
                    onChange={(event: SelectChangeEvent<string>) => {
                      const newValue: DocumentIndemnity | undefined =
                        event.target.value === includesIndemnity
                          ? undefined
                          : (event.target.value as DocumentIndemnity);

                      onSetLegalFolderDocument!((oldLegalFolder) => {
                        return {
                          ...oldLegalFolder,
                          indemnity: newValue,
                        };
                      });
                    }}
                    inputProps={{
                      name: 'age',
                      id: 'outlined-age-native-simple',
                    }}
                    error={indemnityRequired && (indemnity === 'NONE' || !indemnity)}
                  >
                    {containerIndemnityKeys.map((key) => {
                      return (
                        <option value={key} key={key}>
                          {containerIndemnity[key as keyof typeof containerIndemnity]}
                        </option>
                      );
                    })}
                    {customIndemnity ? (
                      <option value={includesIndemnity} key={includesIndemnity}>
                        {includesIndemnity}
                      </option>
                    ) : undefined}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box m={3} />

        <Grid container padding={0} spacing={2} className={s.dataBlock}>
          <Grid xs={12}>
            <Divider />
            <Box m={3} />
          </Grid>
        </Grid>

        <Grid container padding={0} spacing={2} className={s.dataBlock}>
          <Grid xs={12}>
            <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
              <>
                <Grid xs={5} md={2}>
                  <Typography variant="body2" className="label-title">
                    Start date:
                  </Typography>
                </Grid>
                <Grid xs={6} md={3}>
                  <DatePicker
                    value={startDate || null}
                    onChange={(date) => {
                      onSetLegalFolderDocument!((oldLegalFolder) => {
                        return {
                          ...oldLegalFolder,
                          startDate: date,
                        };
                      });
                      // setSelected({ ...selected, [headCell.id]: date });
                    }}
                    componentsProps={{
                      actionBar: { actions: ['clear'] as PickersActionBarAction[] },
                    }}
                    renderInput={(props) => (
                      <TextField variant="outlined" fullWidth size="small" {...props} />
                    )}
                  ></DatePicker>
                </Grid>
                <Grid xs={1}></Grid>
                <Grid xs={5} md={2}>
                  <Typography variant="body2" className="label-title">
                    End date:
                  </Typography>
                </Grid>
                <Grid xs={6} md={3}>
                  <DatePicker
                    value={endDate || null}
                    onChange={(date) => {
                      onSetLegalFolderDocument!((oldLegalFolder) => {
                        return {
                          ...oldLegalFolder,
                          endDate: date,
                        };
                      });
                      // setSelected({ ...selected, [headCell.id]: date });
                    }}
                    componentsProps={{
                      actionBar: { actions: ['clear'] as PickersActionBarAction[] },
                    }}
                    renderInput={(props) => (
                      <TextField variant="outlined" fullWidth size="small" {...props} />
                    )}
                  ></DatePicker>
                </Grid>
                <Grid xs={1}></Grid>
              </>
              <>
                <Grid xs={2}>
                  <Typography variant="body2" className="label-title">
                    {monetaryTransactionType === MonetaryTransactionType.COST
                      ? 'Cost:'
                      : 'Revenue:'}
                  </Typography>
                </Grid>
                <Grid xs={3}>
                  <CurrencyInputField
                    placeholder="Input value..."
                    size="small"
                    value={monetaryTransactionValue}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onSetLegalFolderDocument!((oldLegalFolder) => {
                        return {
                          ...oldLegalFolder,
                          monetaryTransactionValue: parseFloat(e.target.value),
                        };
                      });
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid xs={7}></Grid>

                <Grid xs={12}>
                  <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
                    <Grid xs={12}>
                      {monetaryTransactionType === MonetaryTransactionType.COST
                        ? 'Cost'
                        : 'Revenue'}{' '}
                      breakdown
                    </Grid>
                    <Grid xs={12}>
                      <MonetaryTransactions
                        monetaryTransactionBreakdowns={monetaryTransactionBreakdowns}
                        onRemoveMonetaryTransaction={onRemoveMonetaryTransaction!}
                        onChangeMonetaryTransaction={onChangeMonetaryTransaction!}
                        monetaryTransactionType={monetaryTransactionType}
                      ></MonetaryTransactions>
                    </Grid>
                    <Grid xs={4}>
                      <div style={{ width: 'fit-content', whiteSpace: 'nowrap' }}>
                        <Typography variant="body2" className="label-title">
                          <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={
                              onAddMonetaryTransaction &&
                              debounce(onAddMonetaryTransaction, DEBOUNCE_TIMEOUT)
                            }
                            disabled={
                              monetaryTransactionBreakdowns.length > 0 &&
                              monetaryTransactionBreakdowns.slice(-1)[0] === null
                            }
                          >
                            Add year
                          </Button>
                        </Typography>
                      </div>
                    </Grid>
                    <Grid xs={8}></Grid>
                  </Grid>
                </Grid>
              </>

              <Grid xs={5} lg={4}>
                <Typography variant="body2" className="label-title">
                  Outside Counsel:
                </Typography>
              </Grid>
              <Grid xs={7} lg={8}>
                <RadioGroup
                  row
                  aria-label="isOriginParty"
                  name="position"
                  value={outsideCounsel ? '1' : '0'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onSetLegalFolderDocument!((oldLegalFolderDocument) => {
                      return {
                        ...oldLegalFolderDocument,
                        outsideCounsel: e.target.value === '1',
                      };
                    });
                  }}
                >
                  <FormControlLabel value="1" control={<Radio color="primary" />} label="Yes" />
                  <FormControlLabel value="0" control={<Radio color="primary" />} label="No" />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box m={3} />

        <Grid container padding={0} spacing={2} className={s.dataBlock}>
          <Grid xs={12}>
            <Divider />
            <Box m={3} />
          </Grid>
        </Grid>

        <Grid container padding={0} spacing={2} className={s.dataBlock}>
          <Grid xs={12}>
            <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
              <>
                <Grid xs={5} lg={4}>
                  <Typography variant="body2" className="label-title">
                    Other Contacts:
                  </Typography>
                </Grid>
                <Grid xs={7} lg={8}></Grid>

                <Grid xs={12}>
                  <Contacts
                    contacts={contacts}
                    setContact={setContact!}
                    deleteContact={deleteContact!}
                  ></Contacts>
                </Grid>

                <Grid xs={5}>
                  <div style={{ width: 'fit-content', whiteSpace: 'nowrap' }}>
                    <Typography variant="body2" className="label-title">
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={onAddNewContact && debounce(onAddNewContact, DEBOUNCE_TIMEOUT)}
                      >
                        Add Contact
                      </Button>
                    </Typography>
                  </div>
                </Grid>
                <Grid xs={7}></Grid>
              </>
            </Grid>
          </Grid>
        </Grid>

        <Box m={3} />

        <Grid container padding={0} spacing={2}>
          <Grid xs={12}>
            <Divider />
            <Box m={3} />
          </Grid>
        </Grid>

        <Grid container padding={0} spacing={2} className={s.dataBlock}>
          <Grid xs={12}>
            <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
              <>
                <Grid xs={5} lg={4}>
                  <Typography variant="body2" className="label-title">
                    Send Reminder Email:
                  </Typography>
                </Grid>

                <Grid xs={7} lg={8}>
                  <FormControl variant="outlined">
                    <Select
                      native
                      value={
                        selectedReviewersReminderPeriod ? [selectedReviewersReminderPeriod?.id] : []
                      }
                      onChange={(event: SelectChangeEvent<string[]>) => {
                        onSetLegalFolderDocument!((oldLegalFolder) => {
                          return {
                            ...oldLegalFolder,
                            selectedReviewersReminderPeriod:
                              reviewerReminderPeriods.find(
                                (period) => period.id === event.target.value
                              ) || reviewerReminderPeriods[0],
                          };
                        });
                      }}
                      // inputProps={{
                      //   name: 'age',
                      //   id: 'outlined-age-native-simple',
                      // }}
                      error={showValidator && !!errors?.selectedReviewersReminderPeriod}
                    >
                      {displayReviewersReminderPeriods.map((period) => {
                        return (
                          <option value={period.id} key={period.id}>
                            {period.name}
                          </option>
                        );
                      })}
                      {customIndemnity ? (
                        <option value={includesIndemnity} key={includesIndemnity}>
                          {includesIndemnity}
                        </option>
                      ) : undefined}
                    </Select>
                  </FormControl>

                  {/* <EasyAutoComplete
                  key={`reviewerReminderPeriods`}
                  items={reviewerReminderPeriods}
                  label=""
                  textFieldStyle="outlined"
                  optionsLabel="name"
                  selected={selectedReviewersReminderPeriod}
                  // onInputChange={(event: object, value: string, reason: string) => {
                  //   setParentDocumentName(value);
                  // }}
                  selectedChanged={(value: IGeneralListItem) => {
                    onSetLegalFolderDocument!((oldLegalFolderDocument) => {
                      return {
                        ...oldLegalFolderDocument,
                        selectedReviewersReminderPeriod: value,
                      };
                    });
                  }}
                  error={showValidator && !!errors?.selectedReviewersReminderPeriod}
                  getOptionSelected={(option: any, value: any) => {
                    return option.id === value.id;
                  }}
                /> */}
                </Grid>

                <Grid xs={5} lg={4}>
                  <Typography variant="body2" className="label-title">
                    Reviewers:
                  </Typography>
                </Grid>
                <Grid xs={7} lg={8} style={{ textAlign: 'right' }}>
                  <Link component="button" onClick={toggleVisible}>
                    <Typography variant="caption">{getHideShowTitle()}</Typography>
                    <ArrowIcon />
                  </Link>
                </Grid>

                <Grid xs={12}>
                  <Reviewers
                    key={JSON.stringify(projectFolderDocument.updatedAt)}
                    readOnly={false}
                    editMode
                    onChange={stakeholderListChanged}
                    reviewers={reviewers}
                    projectLeaders={projectLeaders!}
                    users={usersListAsReviewers}
                    hideDisabled={!showDisabledReviewers}
                  ></Reviewers>
                </Grid>
              </>
            </Grid>
          </Grid>
        </Grid>

        <Box m={3} />

        <Grid container padding={0} spacing={2}>
          <Grid xs={12}>
            <Divider />
            <Box m={3} />
          </Grid>
        </Grid>

        <Grid container padding={0} spacing={2} className={s.dataBlock}>
          <Grid xs={12}>
            <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
              <>
                <Grid xs={5} lg={4}>
                  <Typography variant="body2" className="label-title">
                    Observers:
                  </Typography>
                </Grid>
                <Grid xs={7} lg={8} style={{ textAlign: 'right' }}>
                  <Link component="button" onClick={toggleVisibleObservers}>
                    <Typography variant="caption">{getHideShowObserversTitle()}</Typography>
                    <ObserversArrowIcon />
                  </Link>
                </Grid>

                <Grid xs={12}>
                  <Observers
                    key={JSON.stringify(projectFolderDocument.updatedAt)}
                    readOnly={false}
                    editMode
                    onChange={stakeholderObserversListChanged}
                    observers={observers}
                    projectLeaders={projectLeaders!}
                    users={usersListAsReviewers}
                    hideDisabled={!showDisabledObservers}
                  ></Observers>
                </Grid>
              </>
            </Grid>
          </Grid>
        </Grid>

        <Box m={3} />

        <Grid container padding={0} spacing={2}>
          <Grid xs={12}>
            <Divider />
            <Box m={3} />
          </Grid>
        </Grid>

        <Grid container padding={0} spacing={2} className={s.dataBlock}>
          <Grid xs={12}>
            <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
              <>
                <Grid xs={12}>
                  <Grid container padding={0} spacing={0}>
                    <Grid xs={12}>
                      <Typography variant="body2" className="label-title">
                        notes:
                      </Typography>
                    </Grid>
                    <Grid xs={12}>
                      <TextField
                        size="small"
                        multiline
                        value={notes || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onSetLegalFolderDocument!((oldLegalFolder) => {
                            return {
                              ...oldLegalFolder,
                              notes: e.target.value,
                            };
                          });
                        }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid xs={12}>
                  <Grid container padding={0} spacing={0}>
                    <Grid xs={12}>
                      <Typography variant="body2" className="label-title">
                        Special instructions:
                      </Typography>
                    </Grid>

                    <Grid xs={12}>
                      <TextField
                        size="small"
                        multiline
                        value={specialInstructions || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onSetLegalFolderDocument!((oldLegalFolder) => {
                            return {
                              ...oldLegalFolder,
                              specialInstructions: e.target.value,
                            };
                          });
                        }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
});
