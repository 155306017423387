import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';

import { projectFolderDocumentUpdateVariables } from 'graphql/legalFolders/types/projectFolderDocumentUpdate';

import { pick } from 'lodash';

import { validators } from 'constants/validators';
import { paths } from 'constants/index';
import { useHistory } from 'react-router-dom';

import validate from 'validate.js';

import {
  projectFolderDocument_contract_document,
  projectFolderDocument_contract_document_contacts,
  projectFolderDocument_contract_document_events,
  projectFolderDocument_contract_document_versions,
  projectFolderDocument_contract_document_versions_file,
  projectFolderDocument_contract_document_supportFiles,
  projectFolderDocument_contract_document_container,
} from 'graphql/legalFolders/types/projectFolderDocument';

import { ApolloError, useApolloClient, useLazyQuery, useMutation } from '@apollo/client';

import {
  PROJECT_FOLDER_DOCUMENT_CREATE_MUTATION,
  PROJECT_FOLDER_DOCUMENT_UPDATE_MUTATION,
  GET_PROJECT_FOLDER_DOCUMENT_EVENTS,
  DOCUMENT_REVIEWERS_CHANGE_MUTATION,
  DOCUMENT_PARTY_REWIEW_REQUIRED_UPDATE,
  DOCUMENT_MONETARY_TRANSACTION_BREAKDOVN_CHANGE_MUTATION,
  DOCUMENT_OWNERS_CHANGE_MUTATION,
  DOCUMENT_OBSERVERS_CHANGE_MUTATION,
} from 'graphql/legalFolders/documents';
import { CONTACT_UPDATE_MUTATION, CONTACT_CREATE_MUTATION } from 'graphql/legalFolders/contacts';
import { useUI } from 'contexts/UiContext';

import {
  DocumentIndemnity,
  MonetaryTransactionType,
} from 'graphql/legalFolders/types/graphql-types';
import { containerIndemnity } from 'constants/indemnities';

import { projectFolderDocumentEvents } from 'graphql/legalFolders/types/projectFolderDocumentEvents';

import { keys } from 'lodash';
import { projectFolder_contract_container } from 'graphql/legalFolders/types/projectFolder';
import { apolloErrorHandler, apolloErrorTexts } from 'utils/apolloErrorHandler';
import { IReviewer } from './components/Reviewers/interfaces';

import { IProjectLeader, useProjectLeaders } from './components/hooks/projectLeadersHook';
import { IUsersArrayItem, useReviewers } from './components/hooks/reviewersHook';
import { DOCUMENT_CANCEL, DOCUMENT_SOFT_DELETE } from 'graphql/legalFolders/fileManager';

import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { IDocumentDocusign } from 'graphql/legalFolders/types/IDocumentDocusign';
import { reviewerReminderPeriods } from 'constants/reviewerReminderPeriods';
import { useMsalAccount } from 'hooks/msalAccount';
import { MAX_OWNERS } from 'constants/config';
import { IObserver } from './components/Observers/interfaces';
interface IGeneralListItem {
  id: string;
  name: string;
}

export interface IEvent extends Omit<projectFolderDocument_contract_document_events, '__typename'> {
  ev?: any;
}
export interface IContact
  extends Omit<projectFolderDocument_contract_document_contacts, '__typename'> {
  changed?: boolean;
}

// export interface IReviewer
//   extends Omit<projectFolderDocument_contract_document_reviewers, '__typename'> {}

export interface IDocumentVersion
  extends Omit<projectFolderDocument_contract_document_versions, '__typename' | 'file'>,
    Omit<projectFolderDocument_contract_document_versions_file, '__typename' | 'id'> {
  fileId?: string;
  id: string;
}

export interface IDocumentSupportFile
  extends Omit<projectFolderDocument_contract_document_supportFiles, '__typename'> {}

interface IProjectsListItem {
  key: string;
  name: string;
}

interface IDivisionsListItem {
  key: string;
  name: string;
}

interface IDivisionsListItem {
  key: string;
  name: string;
}

export interface IOwner {
  id: string;
  name: string;
  email: string | null;
}

export interface IManager {
  id: string;
  name: string;
  email: string | null;
  persona: string | null;
}

interface IMonetaryTransactionBreakdown {
  id: string;
  startTimestamp: string;
  monetaryTransactionValue: string;
  changed: boolean;
}

export interface ILegalFolderDocumentData extends projectFolderDocumentUpdateVariables {
  selectedDocumentType?: IGeneralListItem;
  selectedReviewersReminderPeriod?: IGeneralListItem;
  selectedParentDocument?: IGeneralListItem;
  childDocuments: IGeneralListItem[];
  projectFolderName: string;
  projectFolderId: string;
  legalFolderId?: string;
  contacts: IContact[];
  deletedContacts: string[];
  reviewers: IReviewer[];
  observers: IObserver[];
  isOriginParty: boolean;
  includesIndemnity?: string;
  customIndemnity: boolean;
  isValid: boolean;
  showValidator: boolean;
  errors?: any;
  partyReviewStatus: string;
  isPartyReviewRequired: boolean;
  events: IEvent[];
  versions: IDocumentVersion[];
  docusigns: IDocumentDocusign[];
  supportFiles: IDocumentSupportFile[];
  canReview: boolean;
  projects: IProjectsListItem[];
  divisions: IDivisionsListItem[];
  monetaryTransactionValue?: number | null;
  monetaryTransactionBreakdowns: IMonetaryTransactionBreakdown[];
  owners: IOwner[] | null;
  managers: IManager[] | null;
  monetaryTransactionType?: MonetaryTransactionType;
  statusFolder?: string;
  canDocumentBeSigned?: boolean;
  updatedAt?: Date;
  createdAt?: Date;
  currentUserName?: string;
  status?: string;
  canDelete?: boolean;
  container?: projectFolderDocument_contract_document_container | null;
}

const projectFolderDocumentValidators = {
  name: validators.nameText,
  selectedDocumentType: validators.required,
};

const newLegalFolderDocument = (): ILegalFolderDocumentData => ({
  id: '',
  name: '',
  isValid: true,
  showValidator: false,
  contacts: [],
  deletedContacts: [],
  projectFolderName: '',
  projectFolderId: '',
  isOriginParty: false,
  customIndemnity: false,
  childDocuments: [],
  partyReviewStatus: 'NONE',
  events: [],
  isPartyReviewRequired: true,
  reviewers: [],
  observers: [],
  versions: [],
  docusigns: [],
  supportFiles: [],
  canReview: false,
  projects: [],
  divisions: [],
  monetaryTransactionBreakdowns: [],
  owners: [],
  managers: [],
});

export interface IContextState {
  projectFolderDocument: ILegalFolderDocumentData;
  newDocumentStatus: any;
  projectLeaders: IProjectLeader[];
  usersListAsReviewers: IUsersArrayItem[];
  validationSummary: Array<string>;
}

export interface IContextActions {
  onAddNewContact: () => void;
  onAddNewRevenueYear: () => void;
  onSetLegalFolderDocument: (
    cb: (oldLegalFolder: ILegalFolderDocumentData) => ILegalFolderDocumentData
  ) => void;
  onSubmit: () => void;
  onCancel: () => void;
  setContact: (index: number, contact: IContact) => void;
  deleteContact: (index: number) => void;
  refetchEventsList: () => void;
  projectFolderDocumentRefetch: any;
  setNewDocumentStatus: any;
  onSelectProjectsChange: (selectedProjectsList: IProjectsListItem[]) => void;
  onSelectDivisionsChange: (selectedDivisionsList: IDivisionsListItem[]) => void;
  onSelectOwnersChange: (selectedownersList: IOwner[]) => void;
  onAddMonetaryTransaction: () => void;
  onRemoveMonetaryTransaction: (index: number) => void;
  onChangeMonetaryTransaction: (
    index: number,
    monetaryTransaction: { startTimestamp: string; monetaryTransactionValue: string }
  ) => void;
  onDocumentClick: (projectDocumentId: string) => void;
  onCancelProcess: () => Promise<boolean>;
  onDeleteProcess: () => Promise<boolean>;
}

const initialState: IContextState = {
  projectFolderDocument: newLegalFolderDocument(),
  newDocumentStatus: {},
  projectLeaders: [],
  usersListAsReviewers: [],
  validationSummary: [],
};

const ComponentContext = createContext<IContextState & Partial<IContextActions>>(initialState);

interface IProviderProps {
  loadedLegalFolderDocument?: projectFolderDocument_contract_document | null;
  loadedLegalFolder?: projectFolder_contract_container | null;
  refetch?: any;
  onCreated?: (id: string) => void;
  onSelectDocument?: (projectFolderDocumentId: string) => void;
  children: any;
}

const isCurrentUser = (account: Record<string, any>, reportUser: IReviewer): boolean => {
  return (
    account?.idToken?.email === reportUser.employeeEmail ||
    account?.idToken?.preferred_username === reportUser.employeeEmail
  );
};

export const Provider: FC<IProviderProps> = ({
  loadedLegalFolderDocument,
  loadedLegalFolder,
  refetch,
  children,
  onCreated,
  onSelectDocument,
}) => {
  const { formChanged, resetChanged } = useFormChangedDialogContext();
  const { usersArray: usersListAsReviewers, loading: usersListAsReviewersLoading } = useReviewers();
  const { projectLeaders, addProjectLeadersFromProjects } = useProjectLeaders();

  const { msalAccount: currentAccount } = useMsalAccount();
  const client = useApolloClient();

  const history = useHistory();

  const [projectFolderDocument, setLegalFolderDocument] = useState<ILegalFolderDocumentData>(
    newLegalFolderDocument()
  );

  const [newDocumentStatus, setNewDocumentStatus] = useState<any>({});

  const { addSnackbar } = useUI();

  const [createLegalFolderDocumentMutation] = useMutation(PROJECT_FOLDER_DOCUMENT_CREATE_MUTATION);
  const [updateLegalFolderDocumentMutation] = useMutation(PROJECT_FOLDER_DOCUMENT_UPDATE_MUTATION);
  const [documentReviewersChangeMutation] = useMutation(DOCUMENT_REVIEWERS_CHANGE_MUTATION);
  const [documentObserversChangeMutation] = useMutation(DOCUMENT_OBSERVERS_CHANGE_MUTATION);
  const [documentPartyReviewIsRequiredChange] = useMutation(DOCUMENT_PARTY_REWIEW_REQUIRED_UPDATE);
  const [documentMonetaryTransactionBreakdownChange] = useMutation(
    DOCUMENT_MONETARY_TRANSACTION_BREAKDOVN_CHANGE_MUTATION
  );
  const [documentOwnersChangeMutation] = useMutation(DOCUMENT_OWNERS_CHANGE_MUTATION);
  const [contactCreateMutation] = useMutation(CONTACT_CREATE_MUTATION);
  const [contactUpdateMutation] = useMutation(CONTACT_UPDATE_MUTATION);
  const [deleteDocumentMutation] = useMutation(DOCUMENT_SOFT_DELETE);
  const [cancelDocumentMutation] = useMutation(DOCUMENT_CANCEL);

  const [
    getLegalFolderDocumentEvents,
    {
      data: getLegalFolderDocumentEventsData,
      loading: getLegalFolderDocumentEventsLoading,
      refetch: getLegalFolderDocumentEventsRefetch,
      called: getLegalFolderDocumentEventsCalled,
    },
  ] = useLazyQuery<projectFolderDocumentEvents>(GET_PROJECT_FOLDER_DOCUMENT_EVENTS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (
      !usersListAsReviewersLoading &&
      usersListAsReviewers?.length &&
      projectFolderDocument.reviewers.length
    ) {
      setLegalFolderDocument((oldLegalFolderDocument) => {
        let changed = false;
        let reviewers = [...oldLegalFolderDocument.reviewers];
        for (let reviewer of reviewers) {
          if (!reviewer.type) {
            const user = usersListAsReviewers.find((item) => item.id === reviewer.employeeId);
            if (user) {
              reviewer.type = user.discipline || 'UNCONVENTIONAL';
              changed = true;
            }
          }
        }
        let observers = [...oldLegalFolderDocument.observers];
        for (let observer of observers) {
          if (!observer.type) {
            const user = usersListAsReviewers.find((item) => item.id === observer.employeeId);
            if (user) {
              observer.type = user.discipline || 'UNCONVENTIONAL';
              changed = true;
            }
          }
        }
        if (changed) {
          return { ...oldLegalFolderDocument, reviewers, observers };
        }
        return oldLegalFolderDocument;
      });
    }
  }, [usersListAsReviewers, usersListAsReviewersLoading, projectFolderDocument]);

  useEffect(() => {
    if (getLegalFolderDocumentEventsData && !getLegalFolderDocumentEventsLoading) {
      setLegalFolderDocument((projectFolder) => ({
        ...projectFolder,
        events: getLegalFolderDocumentEventsData.contract_document.events,
      }));
    }
  }, [getLegalFolderDocumentEventsData, getLegalFolderDocumentEventsLoading]);

  const refetchEventsList = useCallback(() => {
    const variables = { id: projectFolderDocument.id };
    if (getLegalFolderDocumentEventsCalled) {
      getLegalFolderDocumentEventsRefetch!(variables);
    } else {
      getLegalFolderDocumentEvents({ variables });
    }
  }, [
    getLegalFolderDocumentEvents,
    getLegalFolderDocumentEventsRefetch,
    projectFolderDocument,
    getLegalFolderDocumentEventsCalled,
  ]);

  const validateForm = (newState: any) => {
    const errors = validate(newState, projectFolderDocumentValidators);
    return { ...newState, errors, isValid: errors ? false : true };
  };

  useEffect(() => {
    if (loadedLegalFolderDocument) {
      const {
        id,
        name,
        notes,
        specialInstructions,
        contacts,
        container,
        documentType,
        isOriginParty,
        includesIndemnity,
        startDate,
        endDate,
        parentDocument,
        childDocuments,
        owners,
        managers,
        partyReviewStatus,
        isPartyReviewRequired,
        events,
        reviewers,
        observers,
        versions,
        supportFiles,
        docusigns,
        projects,
        divisions,
        outsideCounsel,
        monetaryTransactionValue,
        monetaryTransactionBreakdowns,
        monetaryTransactionType,
        reviewersReminderMinutePeriod,
        statusFolder,
        canDocumentBeSigned,
        updatedAt,
        createdAt,
        status,
        canDelete,
      } = loadedLegalFolderDocument;

      if (container?.projects?.length) {
        addProjectLeadersFromProjects(container?.projects);
      }

      const indemnityKey = keys(containerIndemnity).find(
        (key) =>
          containerIndemnity[key as keyof typeof containerIndemnity].toLowerCase() ===
          includesIndemnity.toLowerCase()
      );

      let index = 0;
      let sortedReviewers = reviewers ? [...reviewers] : [];
      sortedReviewers.sort((a, b) => {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });

      let sortedObservers = observers ? [...observers] : [];
      sortedObservers.sort((a, b) => {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });

      const mappedReviewers: IReviewer[] = sortedReviewers.map((reviewer) => {
        index++;
        const { persona, reviewStatus, isEnabled, user, order } = reviewer;
        return {
          id: index,
          type: persona || '',
          status: reviewStatus ? reviewStatus.toLowerCase() : '',
          reviewStatus: reviewStatus,
          employeeName: user?.name || '',
          employeeId: user?.id ? user.id : undefined,
          employeeEmail: user?.email ? user?.email : undefined,
          disabled: !isEnabled,
          userFromDatabase: true,
          deleted: false,
          changed: index !== order,
          dbOrder: order,
          originIsEnabled: isEnabled,
        };
      });

      const mappedObservers: IObserver[] = sortedObservers.map((observer) => {
        index++;
        const { persona, reviewStatus, isEnabled, user, order } = observer;
        return {
          id: index,
          type: persona || '',
          status: reviewStatus ? reviewStatus.toLowerCase() : '',
          reviewStatus: reviewStatus,
          employeeName: user?.name || '',
          employeeId: user?.id ? user.id : undefined,
          employeeEmail: user?.email ? user?.email : undefined,
          disabled: !isEnabled,
          userFromDatabase: true,
          deleted: false,
          changed: index !== order,
          dbOrder: order,
          originIsEnabled: isEnabled,
        };
      });

      const mappedVersions: IDocumentVersion[] = versions
        .map((versionItem) => {
          const { createdAt, file, version, event } = versionItem;
          const {
            blobContainer,
            blobName,
            mimeType,
            originalFilename,
            downloadUrl,
            id: fileId,
          } = file || {};
          return {
            createdAt,
            downloadUrl: downloadUrl || null,
            version,
            blobContainer: blobContainer || '',
            blobName: blobName || '',
            mimeType: mimeType || '',
            originalFilename: originalFilename || '',
            event,
            fileId,
            id: versionItem.id,
          };
        })
        .sort((a: any, b: any) => +new Date(b?.createdAt) - +new Date(a?.createdAt));

      const mappedOwners: IOwner[] | undefined = owners
        ?.filter((owner) => owner && owner.isEnabled && owner.user)
        .map((owner) => {
          const { id, email, name } = owner!.user!;
          return {
            id,
            email,
            name,
          };
        });

      const mappedManagers: IManager[] | undefined = managers
        ?.filter(
          (manager) => manager && manager.isEnabled && manager.user && manager.persona !== 'OWNER'
        )
        .map((manager) => {
          const { id, email, name } = manager!.user!;
          return {
            id,
            email,
            name,
            persona: manager.persona,
          };
        });

      setLegalFolderDocument({
        id,
        name,
        notes,
        specialInstructions,
        isValid: true,
        showValidator: false,
        contacts: contacts ? [...contacts] : [],
        deletedContacts: [],
        projectFolderName: container?.name || '',
        projectFolderId: container?.id || '',
        legalFolderId: container?.legalFolder.id,
        selectedDocumentType: documentType ? pick(documentType, ['id', 'name']) : undefined,
        selectedParentDocument: parentDocument ? pick(parentDocument, ['id', 'name']) : undefined,
        childDocuments: childDocuments
          ? childDocuments.map((childDocument) => pick(childDocument, ['id', 'name']))
          : [],
        isOriginParty,
        indemnity: indemnityKey ? (indemnityKey as DocumentIndemnity) : undefined,
        customIndemnity: !indemnityKey && !!includesIndemnity,
        includesIndemnity,
        startDate,
        endDate,
        partyReviewStatus,
        isPartyReviewRequired,
        events,
        reviewers: mappedReviewers,
        observers: mappedObservers,
        versions: mappedVersions,
        docusigns,
        supportFiles,
        canReview: !!mappedReviewers.find(
          (reviewer) =>
            reviewer.status === 'review_requested' &&
            !reviewer.disabled &&
            !!currentAccount &&
            isCurrentUser(currentAccount, reviewer)
        ),
        projects:
          projects?.map((project) => {
            return { key: project.id, name: project.key + ' - ' + project.name };
          }) || [],
        divisions:
          divisions?.map((division) => {
            return { key: division.id, name: division.name };
          }) || [],
        outsideCounsel,
        monetaryTransactionValue: monetaryTransactionValue,
        monetaryTransactionBreakdowns: monetaryTransactionBreakdowns.map((transaction) => {
          return {
            ...transaction,
            startTimestamp: new Date(transaction.startTimestamp).getUTCFullYear().toString(),
            monetaryTransactionValue: transaction.monetaryTransactionValue.toString(),
            changed: false,
          };
        }),
        monetaryTransactionType,
        reviewersReminderMinutePeriod,
        selectedReviewersReminderPeriod: reviewersReminderMinutePeriod
          ? reviewerReminderPeriods.find(
              ({ id }) => reviewersReminderMinutePeriod.toString() === id
            ) || {
              id: reviewersReminderMinutePeriod.toString(),
              name: reviewersReminderMinutePeriod.toString() + ' Hours',
            }
          : reviewerReminderPeriods[0],
        statusFolder,
        canDocumentBeSigned,
        createdAt,
        updatedAt,
        owners: mappedOwners || [],
        managers: mappedManagers || [],
        status,
        canDelete,
        container,
      });
    } else {
      if (loadedLegalFolder) {
        const {
          id: projectFolderId,
          name: projectFolderName,
          documentsMonetaryTransactionType,
          legalFolder,
        } = loadedLegalFolder;
        setLegalFolderDocument((oldState) => ({
          ...oldState,
          projectFolderName,
          projectFolderId,
          legalFolderId: legalFolder.id,
          currentUserName: currentAccount?.name,
          monetaryTransactionType: documentsMonetaryTransactionType,
        }));
      }
    }
  }, [loadedLegalFolderDocument, loadedLegalFolder, currentAccount, addProjectLeadersFromProjects]);

  const onAddNewContact = useCallback(() => {
    formChanged && formChanged();
    setLegalFolderDocument((oldProjectFolderDocument) => {
      return {
        ...oldProjectFolderDocument,
        contacts: [
          ...oldProjectFolderDocument.contacts,
          { id: '', name: '', email: '', phone: '' },
        ],
      };
    });
  }, [formChanged]);

  const onSetLegalFolderDocument = useCallback(
    (cb: (oldState: ILegalFolderDocumentData) => ILegalFolderDocumentData) => {
      formChanged && formChanged();
      setLegalFolderDocument((oldState) => {
        const newState = cb(oldState);
        return validateForm(newState);
      });
    },
    [setLegalFolderDocument, formChanged]
  );

  const tryUpdateProcedure = async ({
    mutation,
    parseResult,
  }: {
    mutation: any;
    parseResult: any;
  }) => {
    let isError = false;
    let result: any;
    let errors;
    try {
      const { data } = await mutation();
      result = parseResult(data);
    } catch (error) {
      errors = apolloErrorTexts(error as ApolloError);
      isError = true;
    }
    return { result, isError, errors };
  };

  const reasignOrder = useCallback((items: { id: number; changed: boolean }[]) => {
    const reordered = [];
    for (let i = 0; i < items.length; i++) {
      reordered.push({ ...items[i] });
    }
    let current = 1;
    for (let i = 0; i < reordered.length; i++) {
      if (reordered[i].changed) {
        reordered[i].id = current;
        current++;
      } else {
        current = reordered[i].id + 1;
      }
    }
    return reordered;
  }, []);

  const updateProcedure = async (
    {
      mutation,
      parseResult,
    }: {
      mutation: any;
      parseResult: any;
    },
    showSuccessSnackbar = true
  ) => {
    let result = undefined;
    try {
      showSuccessSnackbar &&
        addSnackbar!({
          text: 'please wait ...',
          severity: 'info',
        });
      // await apolloClient.resetStore();
      const { data } = await mutation();
      result = parseResult(data);
      if (result) {
        showSuccessSnackbar &&
          addSnackbar!({
            text: 'Success',
            severity: 'success',
          });
      } else {
        addSnackbar!({
          text: 'Unable to process request, please try again',
          severity: 'error',
        });
      }
    } catch (error) {
      apolloErrorHandler(addSnackbar!)(error as ApolloError);
    }
    return result;
  };

  const onDeleteProcess = useCallback(async () => {
    let success = false;
    const variables = { documentId: projectFolderDocument.id };
    try {
      const { data } = await deleteDocumentMutation({
        variables,
      });
      if (data?.contract_documentDelete) {
        addSnackbar!({ text: 'Document is deleted', severity: 'success' });
        success = true;
      } else {
        addSnackbar!({ text: 'Unable to process request, please try again', severity: 'error' });
      }
    } catch (error) {
      apolloErrorHandler(addSnackbar!)(error as ApolloError);
    }
    if (success) {
      await client.resetStore();
    }
    return success;
  }, [addSnackbar, deleteDocumentMutation, projectFolderDocument, client]);

  const onCancelProcess = useCallback(async () => {
    let success = false;
    const variables = { documentId: projectFolderDocument.id };
    try {
      const { data } = await cancelDocumentMutation({
        variables,
      });
      if (data?.contract_documentCancel?.eventType) {
        addSnackbar!({ text: 'Document is canceled', severity: 'success' });
        success = true;
      } else {
        addSnackbar!({ text: 'Unable to process request, please try again', severity: 'error' });
      }
    } catch (error) {
      apolloErrorHandler(addSnackbar!)(error as ApolloError);
    }
    if (success) {
      await client.resetStore();
    }
    return success;
  }, [addSnackbar, cancelDocumentMutation, projectFolderDocument, client]);

  const onSubmitValidate = useCallback(() => {
    const { isValid, name, divisions, owners, id } = projectFolderDocument;
    const validationResult = validateForm(projectFolderDocument);
    const { errors } = validationResult;
    if (
      !!errors ||
      !isValid ||
      !name ||
      !divisions.length ||
      (parseInt(id) > 0 && (!owners || owners?.length > MAX_OWNERS || owners.length < 1))
    ) {
      setLegalFolderDocument({ ...validationResult, showValidator: true });
      return false;
    }
    return true;
  }, [projectFolderDocument]);

  const onCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const onSubmit = async () => {
    if (!onSubmitValidate()) {
      return;
    }

    let contactsError = false;

    for (const contact of projectFolderDocument.contacts) {
      if (contact.changed) {
        let contactId = undefined;
        try {
          if (parseInt(contact.id) > 0) {
            const { data } = await contactUpdateMutation({ variables: contact });
            contactId = data?.contract_contactUpdate?.id;
          } else {
            const { data } = await contactCreateMutation({ variables: contact });
            contactId = data?.contract_contactCreate?.id;
          }
          contact.id = contactId;
        } catch (error) {
          apolloErrorHandler(addSnackbar!)(error as ApolloError);
          contactsError = true;
        }
      }
    }

    if (contactsError) {
      addSnackbar!({ text: 'Contact update error', severity: 'error' });
    }

    let changed = false;

    const {
      selectedDocumentType,
      selectedParentDocument,
      reviewers,
      observers,
      isPartyReviewRequired,
      selectedReviewersReminderPeriod,
    } = projectFolderDocument;

    let newLegalFolderDocumentId: string | undefined = undefined;

    const projectIdsToSave: string[] = projectFolderDocument.projects.map((project) => project.key);
    const oldProjectIds: string[] =
      loadedLegalFolderDocument?.projects?.map((project) => project.id) || [];

    const divisionIdsToSave: string[] = projectFolderDocument.divisions.map(
      (division) => division.key
    );
    const oldDivisionIds: string[] =
      loadedLegalFolderDocument?.divisions?.map((division) => division.id) || [];

    const contactIdsToSave: string[] = projectFolderDocument.contacts
      .map((contact) => contact.id)
      .filter((id) => parseInt(id) > 0);

    const oldContactIds: string[] =
      loadedLegalFolderDocument?.contacts?.map((contact) => contact.id) || [];

    if (parseInt(projectFolderDocument.id) > 0) {
      const projectIdsToRemove = oldProjectIds.filter((id) => !projectIdsToSave?.includes(id));
      const projectIdsToAdd = projectIdsToSave.filter((id) => !oldProjectIds?.includes(id));

      const divisionIdsToRemove = oldDivisionIds.filter((id) => !divisionIdsToSave?.includes(id));
      const divisionIdsToAdd = divisionIdsToSave.filter((id) => !oldDivisionIds?.includes(id));

      const contactIdsToRemove = oldContactIds.filter(
        (id) => !contactIdsToSave?.includes(id) && id !== ''
      );
      const contactIdsToAdd = contactIdsToSave.filter(
        (id) => !oldContactIds?.includes(id) && id !== ''
      );

      const saveData = {
        ...projectFolderDocument,
        documentTypeId: selectedDocumentType?.id,
        reviewersReminderMinutePeriod: selectedReviewersReminderPeriod?.id
          ? parseInt(selectedReviewersReminderPeriod?.id)
          : null,
        parentDocumentId: selectedParentDocument?.id || null,
        projectIdsToAdd: projectIdsToAdd && projectIdsToAdd.length ? projectIdsToAdd : undefined,
        projectIdsToRemove:
          projectIdsToRemove && projectIdsToRemove.length ? projectIdsToRemove : undefined,
        divisionIdsToAdd:
          divisionIdsToAdd && divisionIdsToAdd.length ? divisionIdsToAdd : undefined,
        divisionIdsToRemove:
          divisionIdsToRemove && divisionIdsToRemove.length ? divisionIdsToRemove : undefined,
        contactIdsToAdd: contactIdsToAdd && contactIdsToAdd.length ? contactIdsToAdd : undefined,
        contactIdsToRemove:
          contactIdsToRemove && contactIdsToRemove.length ? contactIdsToRemove : undefined,
      };

      const result = await updateProcedure({
        mutation: () =>
          updateLegalFolderDocumentMutation({
            variables: saveData,
          }),
        parseResult: (data: any) => data?.contract_documentUpdate?.id,
      });
      changed = changed || !!result;
    } else {
      const saveData = {
        ...projectFolderDocument,
        reviewersReminderMinutePeriod: selectedReviewersReminderPeriod?.id
          ? parseInt(selectedReviewersReminderPeriod?.id)
          : null,
        documentTypeId: selectedDocumentType?.id,
        parentDocumentId: selectedParentDocument?.id || null,
        projectIds: projectIdsToSave,
        divisionIds: divisionIdsToSave,
        contactIds: contactIdsToSave,
      };

      newLegalFolderDocumentId = await updateProcedure({
        mutation: () =>
          createLegalFolderDocumentMutation({
            variables: saveData,
          }),
        parseResult: (data: any) => data?.contract_documentCreate?.id,
      });
      changed = changed || !!newLegalFolderDocumentId;
    }

    if (reviewers.length && (newLegalFolderDocumentId || parseInt(projectFolderDocument.id) > 0)) {
      const reorderedReviewers = reasignOrder(reviewers) as IReviewer[];
      const reviewersToAdd = reorderedReviewers
        .filter((review) => !review.userFromDatabase && !review.deleted && review.employeeId)
        .map((reviewer) => {
          return {
            userId: reviewer.employeeId,
            order: reviewer.id,
            // persona: reviewer.type,
            isEnabled: !reviewer.disabled,
          };
        });

      const reviewersToModify = reorderedReviewers
        .filter((review) => review.changed && review.userFromDatabase)
        .map((reviewer) => {
          return {
            userId: reviewer.employeeId,
            order: reviewer.id,
            // persona: reviewer.type,
            isEnabled: !reviewer.disabled,
          };
        });

      if (reviewersToAdd.length || reviewersToModify.length) {
        const result = await updateProcedure(
          {
            mutation: () =>
              documentReviewersChangeMutation({
                variables: {
                  documentId: newLegalFolderDocumentId
                    ? newLegalFolderDocumentId
                    : projectFolderDocument.id,
                  reviewersToAdd,
                  reviewersToModify,
                },
              }),
            parseResult: (data: any) => data?.contract_documentReviewersChange?.eventType,
          },
          false
        );
        changed = changed || !!result;
      }
    }

    if (observers.length && (newLegalFolderDocumentId || parseInt(projectFolderDocument.id) > 0)) {
      const reorderedObservers = reasignOrder(observers) as IObserver[];
      const observersToAdd = reorderedObservers
        .filter((review) => !review.userFromDatabase && !review.deleted && review.employeeId)
        .map((observer) => {
          return {
            userId: observer.employeeId,
            order: observer.id,
            // persona: observer.type,
            isEnabled: !observer.disabled,
          };
        });

      const observersToModify = reorderedObservers
        .filter((review) => review.changed && review.userFromDatabase)
        .map((observer) => {
          return {
            userId: observer.employeeId,
            order: observer.id,
            // persona: observer.type,
            isEnabled: !observer.disabled,
          };
        });

      if (observersToAdd.length || observersToModify.length) {
        const result = await updateProcedure(
          {
            mutation: () =>
              documentObserversChangeMutation({
                variables: {
                  documentId: newLegalFolderDocumentId
                    ? newLegalFolderDocumentId
                    : projectFolderDocument.id,
                  observersToAdd,
                  observersToModify,
                },
              }),
            parseResult: (data: any) => data?.contract_documentObserversChange?.eventType,
          },
          false
        );
        changed = changed || !!result;
      }
    }

    if (
      loadedLegalFolderDocument?.isPartyReviewRequired !== isPartyReviewRequired &&
      (isPartyReviewRequired || loadedLegalFolderDocument)
    ) {
      const result = await updateProcedure(
        {
          mutation: () =>
            documentPartyReviewIsRequiredChange({
              variables: {
                documentId: newLegalFolderDocumentId
                  ? newLegalFolderDocumentId
                  : projectFolderDocument.id,
                isRequired: isPartyReviewRequired,
              },
            }),
          parseResult: (data: any) => data?.contract_documentPartyReviewIsRequiredChange?.eventType,
        },
        false
      );
      changed = changed || !!result;
    }

    const { monetaryTransactionBreakdowns } = projectFolderDocument;

    const newTransactions = monetaryTransactionBreakdowns.filter((t) => t.id === '');

    const updatedTransactions = monetaryTransactionBreakdowns.filter(
      (t) => t.id !== '' && t.changed
    );

    const keptTransactionIds = monetaryTransactionBreakdowns
      .filter((t) => t.id !== '')
      .map((t) => t.id);

    const deletedTransactionIds = loadedLegalFolderDocument?.monetaryTransactionBreakdowns.filter(
      (t) => !keptTransactionIds.includes(t.id)
    );

    if (
      (!!deletedTransactionIds && deletedTransactionIds.length) ||
      updatedTransactions.length ||
      newTransactions.length
    ) {
      await updateProcedure(
        {
          mutation: () =>
            documentMonetaryTransactionBreakdownChange({
              variables: {
                documentId: newLegalFolderDocumentId || projectFolderDocument.id,
                monetaryTransactionBreakdownsToAdd: newTransactions.length
                  ? newTransactions.map((t) => ({
                      value: parseFloat(t.monetaryTransactionValue),
                      startTimestamp: new Date(parseInt(t.startTimestamp), 1, 1),
                    }))
                  : undefined,
                monetaryTransactionBreakdownsToModify: updatedTransactions.length
                  ? updatedTransactions.map((t) => ({
                      monetaryTransactionBreakdownId: t.id,
                      value: parseFloat(t.monetaryTransactionValue),
                      startTimestamp: new Date(parseInt(t.startTimestamp), 1, 1),
                    }))
                  : undefined,
                monetaryTransactionBreakdownsToRemove:
                  !!deletedTransactionIds && deletedTransactionIds.length
                    ? deletedTransactionIds.map((t) => ({ monetaryTransactionBreakdownId: t.id }))
                    : undefined,
              },
            }),
          parseResult: (data: any) => data,
        },
        false
      );
      changed = true;
    }

    if (!newLegalFolderDocumentId) {
      const oldOwnersIds =
        loadedLegalFolderDocument?.owners
          ?.filter((owner) => owner.isEnabled)
          .map((owner) => owner.user?.id) || [];
      const oldDisabledOwnersIds =
        loadedLegalFolderDocument?.owners
          ?.filter((owner) => !owner.isEnabled)
          .map((owner) => owner.user?.id) || [];
      const newOwnersIds = projectFolderDocument.owners?.map((owner) => owner.id) || [];

      const ownersToAdd = newOwnersIds
        .filter(
          (itemId) => !oldOwnersIds.includes(itemId) && !oldDisabledOwnersIds.includes(itemId)
        )
        .map((itemId) => ({ userId: itemId }));

      const ownersToDelete = oldOwnersIds
        .filter((itemId) => !newOwnersIds.includes(itemId!))
        .map((itemId) => ({ userId: itemId, isEnabled: false }));

      const ownersToEnable = oldDisabledOwnersIds
        .filter((itemId) => newOwnersIds.includes(itemId!))
        .map((itemId) => ({ userId: itemId, isEnabled: true }));

      if (ownersToAdd.length || ownersToDelete.length || ownersToEnable.length) {
        const { result, isError, errors } = await tryUpdateProcedure({
          mutation: () =>
            documentOwnersChangeMutation({
              variables: {
                documentId: newLegalFolderDocumentId || projectFolderDocument.id,
                ownersToAdd: ownersToAdd,
                ownersToModify: [...ownersToDelete, ...ownersToEnable],
              },
            }),
          parseResult: (data: any) => {
            return data;
          },
        });
        if (isError || !result) {
          addSnackbar!({
            text: "Unable to set Document's owner." + errors?.join(' '),
            severity: 'error',
          });
        }
        changed = true;
      }
    }

    if (!!newLegalFolderDocumentId) {
      if (onCreated) {
        onCreated(newLegalFolderDocumentId);
      } else {
        const newPath = paths.client.PROJECT_FOLDER_DOCUMENT_ID.replace(
          ':documentId',
          newLegalFolderDocumentId
        );
        history.push(newPath);
      }
    }

    if (changed) {
      // refetch && refetch();
      resetChanged && resetChanged();
      client.resetStore();
    }
  };

  const setContact = useCallback(
    (index: number, newContact: any) => {
      formChanged && formChanged();
      setLegalFolderDocument((oldLegalFolder) => {
        return {
          ...oldLegalFolder,
          contacts: oldLegalFolder.contacts
            .slice(0, index)
            .concat({ ...newContact, changed: true })
            .concat(oldLegalFolder.contacts.slice(index + 1)),
        };
      });
    },
    [formChanged]
  );

  const deleteContact = useCallback(
    (index: number) => {
      formChanged && formChanged();
      setLegalFolderDocument((oldLegalFolderDocument) => {
        return {
          ...oldLegalFolderDocument,
          contacts: oldLegalFolderDocument.contacts
            .slice(0, index)
            .concat(oldLegalFolderDocument.contacts.slice(index + 1)),
          deletedContacts: oldLegalFolderDocument.deletedContacts.concat(
            parseInt(oldLegalFolderDocument.contacts[index].id) > 0
              ? [oldLegalFolderDocument.contacts[index].id]
              : []
          ),
        };
      });
    },
    [formChanged]
  );

  const onSelectProjectsChange = useCallback(
    (selectedProjectsList: IProjectsListItem[]) => {
      formChanged && formChanged();
      setLegalFolderDocument((legalFolderDocument) => {
        return { ...legalFolderDocument, projects: selectedProjectsList };
      });
    },
    [setLegalFolderDocument, formChanged]
  );

  const onSelectDivisionsChange = useCallback(
    (selectedDivisionsList: IDivisionsListItem[]) => {
      formChanged && formChanged();
      setLegalFolderDocument((legalFolderDocument) => {
        return { ...legalFolderDocument, divisions: selectedDivisionsList };
      });
    },
    [setLegalFolderDocument, formChanged]
  );

  const onSelectOwnersChange = useCallback(
    (selectedOwnersList: IOwner[] | null) => {
      formChanged && formChanged();
      setLegalFolderDocument((legalFolderDocument) => {
        return { ...legalFolderDocument, owners: selectedOwnersList };
      });
    },
    [setLegalFolderDocument, formChanged]
  );

  const onAddMonetaryTransaction = useCallback(() => {
    formChanged && formChanged();
    setLegalFolderDocument((legalFolderDocument) => {
      const { monetaryTransactionBreakdowns } = legalFolderDocument;
      return {
        ...legalFolderDocument,
        monetaryTransactionBreakdowns: [
          ...monetaryTransactionBreakdowns,
          {
            id: '',
            startTimestamp: '',
            monetaryTransactionValue: '0',
            changed: true,
          },
        ],
      };
    });
  }, [formChanged]);

  const onRemoveMonetaryTransaction = useCallback(
    (index: number) => {
      formChanged && formChanged();
      setLegalFolderDocument((legalFolderDocument) => {
        const { monetaryTransactionBreakdowns } = legalFolderDocument;
        return {
          ...legalFolderDocument,
          monetaryTransactionBreakdowns: [
            ...monetaryTransactionBreakdowns.slice(0, index),
            ...monetaryTransactionBreakdowns.slice(index + 1),
          ],
        };
      });
    },
    [formChanged]
  );

  const onChangeMonetaryTransaction = useCallback(
    (
      index: number,
      monetaryTransaction: { startTimestamp: string; monetaryTransactionValue: string }
    ) => {
      formChanged && formChanged();
      setLegalFolderDocument((legalFolderDocument) => {
        const { monetaryTransactionBreakdowns } = legalFolderDocument;
        const newMonetaryTransactionBreakdowns = [...monetaryTransactionBreakdowns];
        newMonetaryTransactionBreakdowns[index] = {
          ...newMonetaryTransactionBreakdowns[index],
          ...monetaryTransaction,
          changed: true,
        };
        return {
          ...legalFolderDocument,
          monetaryTransactionBreakdowns: newMonetaryTransactionBreakdowns,
        };
      });
    },
    [formChanged]
  );

  const onDocumentClick = useCallback(
    (projectFolderId: string) => {
      onSelectDocument && onSelectDocument(projectFolderId);
    },
    [onSelectDocument]
  );

  const resetTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const refetchDocument = useCallback(async () => {
    await refetch();
    if (resetTimer.current) {
      clearTimeout(resetTimer.current);
    }
    resetTimer.current = setTimeout(() => {
      resetTimer.current = null;
      client.resetStore();
    }, 1000);
  }, [client, refetch]);

  const validationSummary = useMemo(() => {
    const summary = [];
    const isNewDocument = !(parseInt(projectFolderDocument.id) > 0);

    const { owners, divisions, errors } = projectFolderDocument;
    if (!isNewDocument) {
      if (!owners || owners.length < 1) {
        summary.push('At least one document owner is required');
      } else {
        if (owners.length > MAX_OWNERS) {
          summary.push('Maximum of ' + MAX_OWNERS + ' document owners is allowed');
        }
      }
    }

    if (!divisions.length) {
      summary.push('Document must be assigned to at least one division');
    }

    if (errors && Object.keys(errors).length > 0) {
      if (Object.keys(errors).length === 1) {
        if (!summary.length) {
          summary.push('Please check an error found in a document field');
        } else {
          summary.push('Please also check an error found in another document field');
        }
      } else {
        if (!summary.length) {
          summary.push('Please check errors found in document fields');
        } else {
          summary.push('Please also check errors found in other document fields');
        }
      }
    }
    return summary;
  }, [projectFolderDocument]);

  return (
    <ComponentContext.Provider
      value={{
        projectFolderDocument,
        onAddNewContact,
        onSetLegalFolderDocument,
        onSubmit,
        onCancel,
        setContact,
        deleteContact,
        refetchEventsList,
        projectFolderDocumentRefetch: refetchDocument,
        newDocumentStatus,
        setNewDocumentStatus,
        onSelectProjectsChange,
        onSelectDivisionsChange,
        onSelectOwnersChange,
        onAddMonetaryTransaction,
        onRemoveMonetaryTransaction,
        onChangeMonetaryTransaction,
        onDocumentClick,
        projectLeaders,
        usersListAsReviewers,
        validationSummary,
        onDeleteProcess,
        onCancelProcess,
      }}
    >
      {children}
    </ComponentContext.Provider>
  );
};

export const useComponentContext = () => useContext(ComponentContext);
