import React, { FC, useCallback, useMemo, useState } from 'react';
import { Typography, Divider, Tooltip, IconButton, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { find, forEach, pick, uniqueId } from 'lodash';

import { FileLogsProps } from './interfaces';

import './index.scss';

import { formatTime } from 'utils/time';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { IDocumentVersion } from 'template/LegalFolderDocument/LegalFolderDocumentContext';
import { AlertDialog, ConfirmationDialog } from 'components';
import { id } from 'utils/id';
import { DOCUMENT_VERSION_SOFT_DELETE } from 'graphql/legalFolders/fileManager';
import { useMutation } from '@apollo/client';
import { getVersion } from '../EventCommentLogs';
import { Skeleton } from '@mui/lab';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { projectFolderDocument_contract_document_events } from 'graphql/legalFolders/types/projectFolderDocument';
import { Asterisks } from 'components/Asterisks/Asterisks';
import { CONTRACT_DOCUSIGN_ENVELOPE_STATUS_UPDATE } from 'graphql/legalFolders/docusign';

interface ReportEventExtended
  extends Omit<projectFolderDocument_contract_document_events, '__typename'> {
  isEnabled: boolean;
}

const useStyles = makeStyles((theme) => ({
  hoverWarning: {
    '&:hover': {
      color: 'red',
    },
  },
}));

export const FileLogs: FC<FileLogsProps> = ({
  documentId,
  versions,
  docusigns,
  documentArchived,
  refetchDocument,
  projectFolderDocument,
}: FileLogsProps) => {
  const classes = useStyles();

  const [deleteFileConfirmationMessage, setDeleteFileConfirmationMessage] = useState<any>('');
  const [deleteFileConfirmationComment, setDeleteFileConfirmationComment] = useState('');
  const [deleteFileConfirmationOpen, setDeleteFileConfirmationOpen] = useState(false);
  const [deleteFileConfirmationFileId, setDeleteFileConfirmationFileId] =
    useState<IDocumentVersion>();

  const [openReviewersWarning, setReviewersWarning] = useState(false);
  const [versionPendingReviewersEvents, setversionPendingReviewersEvents] =
    useState<Array<ReportEventExtended>>();

  const [mutationErrorMessage, setMutationErrorMessage] = useState('');
  const [mutationErrorOpen, setMutationErrorOpen] = useState(false);

  const [deleteFile, { loading: deletingFile }] = useMutation(DOCUMENT_VERSION_SOFT_DELETE);
  const [voidEnvelope, { loading: voidEnvelopeLoading }] = useMutation(
    CONTRACT_DOCUSIGN_ENVELOPE_STATUS_UPDATE
  );
  const [isRefetching, setIsRefetching] = useState(false);

  const getVersionPendingReviewers = useCallback(
    (version: any) => {
      const foundPendingReviewers: Array<ReportEventExtended> = [];
      const { reviewers, events } = projectFolderDocument;

      const pendingReviewers = reviewers?.length
        ? projectFolderDocument.reviewers
            .filter((reviewer) => reviewer.reviewStatus === 'REVIEW_REQUESTED')
            .map((reviewer) => ({
              ...pick(reviewer, ['employeeId', 'employeeName', 'employeeEmail']),
              isEnabled: !reviewer.disabled,
            }))
        : undefined;

      if (pendingReviewers?.length && events?.length) {
        const reviewersIds = pendingReviewers.map((reviewer) => reviewer.employeeId);

        const checkEvents = events
          .filter(
            (event: any) =>
              event.eventType === 'DOCUMENT_REVIEW_REQUESTED' &&
              reviewersIds.includes(event.subject?.user?.id)
          )
          .reverse();

        forEach(pendingReviewers, (pendingReviewer) => {
          const lastEvent = checkEvents.find(
            (event) => event.subject?.user?.id === pendingReviewer.employeeId
          );
          if (lastEvent && lastEvent.version?.version === version.version) {
            foundPendingReviewers.push({ ...lastEvent, isEnabled: pendingReviewer.isEnabled });
          }
        });
      }

      return foundPendingReviewers;
    },
    [projectFolderDocument]
  );

  const getDocusigns = useCallback(
    (versionId: string) => {
      return docusigns?.filter(
        (docusign) =>
          docusign.documentVersionId === versionId &&
          docusign.envelope?.status &&
          !['completed', 'voided'].includes(docusign.envelope!.status!)
      );
    },
    [docusigns]
  );

  const deleteFileHandler = useCallback(
    (version: IDocumentVersion) => {
      if (!document) return;

      const versionPendingReviewers = getVersionPendingReviewers(version);
      if (versionPendingReviewers.length) {
        setversionPendingReviewersEvents(versionPendingReviewers);
        setReviewersWarning(true);
        return;
      }

      const relatedDocusignDocuments = getDocusigns(version.id);
      let message = '';
      if (!relatedDocusignDocuments?.length) {
        message = `Are you sure you want to delete document version ${getVersion({
          version,
        })}?`;
      } else {
        if (relatedDocusignDocuments?.length === 1) {
          message = `There is one related DocuSign document waiting for completition.<br />
            It will be automatically <div style="display:inline-block;color:red">Voided</div> !<br /><br />
            Are you sure you want to delete document version ${getVersion({
              version,
            })} and to Void the DocuSign document?`;
        } else {
          message = `There are ${
            relatedDocusignDocuments?.length
          } related DocuSign documents waiting for completition. <br />They will be automatically <div style="display:inline-block;color:red">Voided</div> !<br /><br />
            Are you sure you want to delete document version ${getVersion({
              version,
            })} and to Void DocuSign documents?`;
        }
      }

      setDeleteFileConfirmationFileId(version);
      setDeleteFileConfirmationMessage(message);
      setDeleteFileConfirmationOpen(true);
    },
    [getVersionPendingReviewers, getDocusigns]
  );

  const hasPendingReviewersMessage = useMemo(() => {
    const hasDisabledUsers = find(versionPendingReviewersEvents, (event) => !event.isEnabled);
    return (
      <>
        Report version has pending reviewers. <br />
        Please recall review requests for following reviewers.
        <ul>
          {versionPendingReviewersEvents?.map((event) => {
            return (
              <li key={event?.subject?.user?.id}>
                {event?.subject?.user?.name}{' '}
                {!event.isEnabled ? (
                  <>
                    (disabled user
                    <Asterisks count={1} />)
                  </>
                ) : undefined}
              </li>
            );
          })}
        </ul>
        {hasDisabledUsers ? (
          <>
            <br />
            <Asterisks count={1} /> Please, enable disabled users first.
          </>
        ) : undefined}
      </>
    );
  }, [versionPendingReviewersEvents]);

  const deleteFileConfirmationCloseHandler = useCallback(
    async (ok: boolean) => {
      setDeleteFileConfirmationOpen(false);

      if (ok) {
        setIsRefetching(true);

        const relatedDocusignDocuments = getDocusigns(deleteFileConfirmationFileId!.id);
        let docusignCompleted = true;
        if (relatedDocusignDocuments) {
          try {
            for (const docusign of relatedDocusignDocuments) {
              voidEnvelope({
                variables: {
                  documentId: documentId,
                  envelopeId: docusign.envelopeId,
                  status: 'voided',
                  statusReason: 'Deleted related document',
                },
              });
            }
          } catch (e: any) {
            setMutationErrorMessage(e?.message);
            setMutationErrorOpen(true);
            docusignCompleted = false;
          }
        }

        if (docusignCompleted) {
          deleteFile({
            variables: {
              documentId: documentId,
              documentFileId: deleteFileConfirmationFileId?.fileId,
              version: deleteFileConfirmationFileId?.version,
              comment: deleteFileConfirmationComment,
            },
          })
            .then(() => {
              refetchDocument({ variables: { id: id() } }).then(() => {
                setIsRefetching(false);
              });
            })
            .catch((e) => {
              setMutationErrorMessage(e?.message);
              setMutationErrorOpen(true);
              setIsRefetching(false);
            });
        } else {
          refetchDocument({ variables: { id: id() } }).then(() => {
            setIsRefetching(false);
          });
        }
      }
      setDeleteFileConfirmationComment('');
    },
    [
      deleteFile,
      documentId,
      refetchDocument,
      deleteFileConfirmationComment,
      deleteFileConfirmationFileId,
      getDocusigns,
      voidEnvelope,
    ]
  );

  const somethingIsLoading = (): boolean => {
    return deletingFile || voidEnvelopeLoading || isRefetching;
  };

  return (
    <div className="document-section">
      <AlertDialog
        title="Error"
        message={mutationErrorMessage}
        open={mutationErrorOpen}
        onClose={() => {
          setMutationErrorOpen(false);
        }}
      />
      <AlertDialog
        title="Reviewers List - Action is Required"
        message={hasPendingReviewersMessage}
        open={openReviewersWarning}
        onClose={() => {
          setReviewersWarning(false);
          setversionPendingReviewersEvents(undefined);
        }}
      />
      <ConfirmationDialog
        title="Delete file"
        message={deleteFileConfirmationMessage}
        comment={deleteFileConfirmationComment}
        setComment={setDeleteFileConfirmationComment}
        open={deleteFileConfirmationOpen}
        onClose={deleteFileConfirmationCloseHandler}
      />
      {versions.length === 0 && !somethingIsLoading() && (
        <Typography>
          <u>No Document Versions</u>
        </Typography>
      )}
      <Grid container spacing={1}>
        {!somethingIsLoading() &&
          versions
            .slice()
            .sort((a: any, b: any) => +new Date(b?.createdAt) - +new Date(a?.createdAt))
            .map((version: any, i: number) => (
              <>
                <Grid item xs={11} key={uniqueId()}>
                  <Typography style={{ fontSize: '0.88rem', paddingBottom: 5 }}>
                    <strong style={{ fontWeight: 900 }}>
                      {version?.event?.author?.persona
                        ? version?.event?.author?.persona + ' - '
                        : ''}
                      {version?.event?.author?.user?.name ? (
                        <>{version?.event?.author?.user?.name}</>
                      ) : (
                        'Undefined Uploader'
                      )}
                    </strong>
                  </Typography>
                  {version?.downloadUrl && version?.downloadUrl !== '' ? (
                    <div
                      className={
                        documentArchived && i > 0
                          ? 'file-archive-item-readon-only'
                          : 'file-archive-item'
                      }
                      style={{
                        overflow: 'hidden',
                        // wordBreak: 'break-all',
                        // wordWrap: 'break-word',
                      }}
                    >
                      <Tooltip
                        title={`Filename: ${version?.originalFilename}`}
                        placement="bottom-start"
                        arrow
                      >
                        <a
                          href={version?.downloadUrl}
                          style={{ color: '#000000de' }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {version.blobName}
                        </a>
                      </Tooltip>
                    </div>
                  ) : (
                    version.blobName
                  )}
                  <p>{formatTime(version?.createdAt)}</p>
                  <Divider />
                  <br />
                </Grid>
                <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
                  <div>
                    <IconButton
                      edge="end"
                      onClick={debounce(() => {
                        deleteFileHandler(version);
                      }, DEBOUNCE_TIMEOUT)}
                      style={{ padding: '12px' }}
                      className={classes.hoverWarning}
                    >
                      <Tooltip
                        title={`Filename: ${version?.blobName}`}
                        placement="bottom-start"
                        arrow
                      >
                        <DeleteIcon fontSize="small" />
                      </Tooltip>
                    </IconButton>
                  </div>
                </Grid>
              </>
            ))}
      </Grid>
      {somethingIsLoading() && <Skeleton variant="rectangular" animation="wave" height={200} />}
    </div>
  );
};
